import { FaBars } from 'react-icons/fa';
import styled from 'styled-components';
import Text from './Text';

export const Nav = styled.nav`
    height: 3em;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 1em;
    z-index: 12;
    position: sticky;
    top:0;
   background-color:#141A0480;
   backdrop-filter: blur(10px);
  
  
    ${props => props.theme.breakpoints.lg}{
        padding: 0.2rem 5rem;
    }
`;

export const LeftNav = styled.div`
    align-items: center;
    display:flex;
    gap: 2rem;
`

export const NavLink = styled.ul`
    display: flex;
    font-size: 1.1em;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: default;
    color: ${props => props.theme.colors.text};
`;

export const Item = styled.li`
    list-style:none;
    position: relative;
    font-size:0.8em;
    transition: all ease-in 0.2s;


      &.active{
        color: ${props => props.theme.colors.primary};

        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          border-radius: 4px;
          background: linear-gradient(135deg, rgba(165,120,255,1) 0%, rgba(255,131,189,1) 33%, rgba(255,186,109,1) 66%, rgba(251,233,190,1) 100%);
          bottom: -5px;
          left: 0;
          transform-origin: left;
          transform: scaleX(1);
        }
      }

    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 4px;
        background: linear-gradient(135deg, rgba(165,120,255,1) 0%, rgba(255,131,189,1) 33%, rgba(255,186,109,1) 66%, rgba(251,233,190,1) 100%);
        bottom: -5px;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .3s ease-in-out;
    }

    &:hover{
        &::before {
            transform-origin: left;
            transform: scaleX(1);       
          }
        color: ${props => props.theme.colors.primary};
    }
`

export const Bars = styled(FaBars)`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;

    ${props => props.theme.breakpoints.lg}{
        display: none;
        color: #808080;    
    }
`;

export const NavMenu = styled.div`
    display: none;
    align-items: center;
    margin-right: -24px;
    
    ${props => props.theme.breakpoints.lg}{
        display: flex;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    gap: 0.5rem; 
    cursor: pointer;

      ${props => props.theme.breakpoints.lg}{
        gap: 1rem;
      }
`;
