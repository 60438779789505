const defaultTheme = {
    colors: {
        yellow:"#f5c32c",
        orange:"#fca61f",
        black:"#242d49",
        gray:"#788097",
        purple:"#eed2ff",
        white: "#ffffff",
        darkViolet: "#1F1D36",
        violet:"#3F3351",
        lightViolet: "#864879",
        peach:"#E9A6A6",
        primary: "#ffba6d",
        secondary: "#141A04",
        tertiary: "#835BE1",
        card:{
            blueCard:"#ddf8fe",
            orangeCard:"#fca71f40",
            purpleCard:"#86487940"
        },
        background:{
            default:"#000000",
        },
        text:"#EDF1FC",
},
    typography: {
        inter: `'Inter', sans-serif`,
    },
    gradients: {
        vertical: "linear-gradient(90deg, #010c3e 1%, #03105f 53%, #010c3e)",
        yellow: "linear-gradient(180deg, #fdc50f 25%, #fb982f 100%)",
        radial: "radial-gradient(circle, #0a59f7cc, transparent 40%)",
        lightBlue: "linear-gradient(180deg, #168BFE 0%, #010BA5 100%)",
        teal:"linear-gradient(0deg, #aaaaaa 0%, #00adb5 100%)"
    },
    shadows: {
        navbar: "0px 0.1875em 1.25em #325B4C2F",
        yellow: "0px 1px 10px 3px rgba(251, 161, 40, 0.42)",
        roundedCard: "0px 0.1875em 1.25em #325B4CA2",
        boxShadow: "0 5px 10px #00000030",
        purple: "0 1px 10px 3px rgba(158,59,137,0.42)",
        peach:"0 1px 10px 3px #E9A6A680"
    },
    breakpoints: {
        sm: "@media (min-width: 576px)",
        md: "@media (min-width: 768px)",
        lg: "@media (min-width: 992px)",
        xl: "@media (min-width: 1200px)",
        xxl: "@media (min-width: 1440px)",
    },
    containerWidth: {
        sm: "54rem",
        md: "74rem",
        lg: "82.75rem",
        xl: "84.75rem",
        xxl: "100rem",
    },
  };
  
  export default defaultTheme;