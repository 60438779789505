import { OrbitControls, ScrollControls,ContactShadows } from "@react-three/drei";
import { Overlay } from "./Overlay";
import {Background3D} from "./Background3D";
import {useEffect, useRef, useState} from "react";

export const Experience = ({handleScrollProgress,activeMenu}) => {
    const [scrollElement, setScrollElement] = useState(null);
    const [isScrollElementActive, setIsScrollElementActive] = useState(false);
    useEffect(() => {
        if(scrollElement && !isScrollElementActive) {
            scrollElement.style.touchAction = null;
            setIsScrollElementActive(()=>true);
        }
    }, [scrollElement]);

    const handleScrollElement = (element) => {
       setScrollElement(()=>element);
    };
    return (
        <>
            <hemisphereLight intensity={1} />
            <OrbitControls
                enableZoom={false}
                enableRotate={false}
                enablePan={false}
            />
            <ScrollControls pages={10} damping={0.25} >
                <Overlay handleScrollProgress={handleScrollProgress} activeMenu={activeMenu} handleScrollElement={handleScrollElement}/>
                <directionalLight position={[0, 20, 20]} intensity={1} />
                <Background3D activeMenu={activeMenu}/>
                <ContactShadows scale={30} opacity={0.32} />
            </ScrollControls>
        </>
    );
};