import { useGLTF, useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import gsap from "gsap";
import React, {useEffect, useLayoutEffect, useRef} from "react";

export function Background3D(props) {
    const { nodes, materials } = useGLTF('./models/the_forest_and_the_cat.glb')

        const ref = useRef();
        const tl = useRef();
        const rightCatRef = useRef();
        const middleCatRef = useRef();
        const scroll = useScroll();

        useFrame(() => {
                tl.current.seek(scroll.offset * tl.current.duration());
        });

        useLayoutEffect(() => {
                tl.current = gsap.timeline();

                // VERTICAL ANIMATION

                tl.current.to(
                    ref.current.position,
                    {
                            duration: 4,
                            x: -5,
                            z: 2
                    },
                    0
                );

                tl.current.to(
                    rightCatRef.current.position,
                    {
                            duration: 2,
                            y:0.25
                    },
                    0.5
                );

                tl.current.to(
                    middleCatRef.current.position,
                    {
                            duration: 1.5,
                            y:0.5
                    },
                    3
                );


                tl.current.to(
                    ref.current.position,
                    {
                            duration: 2,
                            x: 0,
                            z: 1
                    },
                    4
                );


               tl.current.to(
                    ref.current.position,
                    {
                            duration: 2,
                            x: -3,
                            y: -1.5,
                            z: -1
                    },
                    8
                );

                tl.current.to(
                    ref.current.rotation,
                    { duration:2, x: 0, y: -Math.PI / 8, z: 0 },
                   10
                );


                tl.current.to(
                    ref.current.rotation,
                    { duration:4, x: 0, y: -Math.PI, z: 0 },
                    14
                );

                tl.current.to(
                    ref.current.position,
                    {
                            duration: 3,
                            x: 0,
                            y: -2,
                            z: 3
                    },
                    15
                );
        }, []);

    return (
        <group {...props}
               dispose={null}
               ref={ref}
               position={[0.5, -1, -1]}
               rotation={[0, -Math.PI / 3, 0]}>
            <mesh geometry={nodes.Object_4.geometry} material={materials['Material.001']} scale={2.519} />
            <mesh geometry={nodes.Object_6.geometry} material={materials.outside} position={[0, 0.893, 0]} scale={[6.053, 1, 6.053]} />
            <mesh geometry={nodes.Object_8.geometry} material={materials.Material_alpha} position={[0.469, 0.774, -1.589]} />
            <mesh geometry={nodes.Object_10.geometry} material={materials.Material_alpha} position={[0.197, 0.774, -1.507]} />
            <mesh geometry={nodes.Object_12.geometry} material={materials['Material.001']} position={[-1.704, 0.533, -1.503]} scale={[0.337, 0.357, 0.337]} />
            <mesh geometry={nodes.Object_14.geometry} material={materials['Material.001']} position={[2.077, 0, -2.578]} rotation={[3.141, -0.439, 3.141]} scale={1.217} />
            <mesh geometry={nodes.Object_16.geometry} material={materials['Material.001']} position={[2.106, 0, -2.43]} rotation={[2.882, 0.18, -2.894]} scale={1.201} />
            <mesh geometry={nodes.Object_18.geometry} material={materials['Material.001']} position={[2.006, 0, -2.299]} rotation={[2.824, 1.15, -2.678]} scale={0.95} />
            <mesh geometry={nodes.Object_20.geometry} material={materials['Material.001']} position={[-3.006, 0, -1.365]} rotation={[-0.005, -0.253, 0.209]} scale={1.543} />
            <mesh geometry={nodes.Object_22.geometry} material={materials['Material.001']} position={[1.923, 0, -2.287]} rotation={[3.014, 1.214, -3.131]} scale={1.415} />
            <mesh geometry={nodes.Object_24.geometry} material={materials['Material.001']} position={[1.713, 0, -2.444]} rotation={[2.802, 0.737, -2.756]} scale={1.095} />
            <mesh geometry={nodes.Object_26.geometry} material={materials['Material.001']} position={[1.828, 0, -2.291]} rotation={[-3.101, 0.837, 2.882]} scale={1.756} />
            <mesh geometry={nodes.Object_28.geometry} material={materials['Material.001']} position={[1.689, 0, -2.314]} rotation={[0.602, -1.05, 0.545]} scale={1.374} />
            <mesh geometry={nodes.Object_30.geometry} material={materials['Material.001']} position={[1.556, 0, -2.294]} rotation={[-0.13, -0.565, -0.09]} scale={1.685} />
            <mesh geometry={nodes.Object_32.geometry} material={materials['Material.001']} position={[1.172, 0, -2.607]} rotation={[-3.096, 0.317, -3.059]} scale={1.779} />
            <mesh geometry={nodes.Object_34.geometry} material={materials['Material.001']} position={[1.22, 0, -2.487]} rotation={[3.123, 1.074, 3.139]} scale={1.402} />
            <mesh geometry={nodes.Object_36.geometry} material={materials['Material.001']} position={[1.329, 0, -2.369]} rotation={[0.223, -0.004, -0.093]} scale={1.304} />
            <mesh geometry={nodes.Object_38.geometry} material={materials['Material.001']} position={[1.226, 0, -2.392]} rotation={[-3.1, 0.883, 2.918]} scale={1.077} />
            <mesh geometry={nodes.Object_40.geometry} material={materials['Material.001']} position={[1.227, 0, -2.331]} rotation={[0.18, -1.062, 0.132]} scale={1.744} />
            <mesh geometry={nodes.Object_42.geometry} material={materials['Material.001']} position={[0.846, 0, -2.622]} rotation={[-2.858, -1.156, -2.641]} scale={1.012} />
            <mesh geometry={nodes.Object_44.geometry} material={materials['Material.001']} position={[0.896, 0, -2.472]} rotation={[1.783, 1.403, -1.545]} scale={1.966} />
            <mesh geometry={nodes.Object_46.geometry} material={materials['Material.001']} position={[1.107, 0, -2.565]} rotation={[-0.215, 0.071, -0.133]} scale={0.923} />
            <mesh geometry={nodes.Object_48.geometry} material={materials['Material.001']} position={[1.068, 0, -2.521]} rotation={[-0.018, -0.495, 0.158]} scale={1.208} />
            <mesh geometry={nodes.Object_50.geometry} material={materials['Material.001']} position={[1.023, 0, -2.481]} rotation={[-2.886, 0.663, 2.992]} scale={1.952} />
            <mesh geometry={nodes.Object_52.geometry} material={materials['Material.001']} position={[1.057, 0, -2.373]} rotation={[2.301, -1.205, 2.37]} scale={0.954} />
            <mesh geometry={nodes.Object_54.geometry} material={materials['Material.001']} position={[1.03, 0, -2.289]} rotation={[-3.115, -0.949, -3.13]} scale={1.041} />
            <mesh geometry={nodes.Object_56.geometry} material={materials['Material.001']} position={[0.831, 0, -2.284]} rotation={[0.022, -0.192, 0]} scale={1.735} />
            <mesh geometry={nodes.Object_58.geometry} material={materials['Material.001']} position={[0.505, 0, -2.622]} rotation={[0.184, -0.882, 0.06]} scale={1.617} />
            <mesh geometry={nodes.Object_60.geometry} material={materials['Material.001']} position={[0.441, 0, -2.548]} rotation={[-0.01, 0.134, 0.002]} scale={1.855} />
            <mesh geometry={nodes.Object_62.geometry} material={materials['Material.001']} position={[0.706, 0, -2.565]} rotation={[0.739, -1.386, 0.69]} scale={1.133} />
            <mesh geometry={nodes.Object_64.geometry} material={materials['Material.001']} position={[0.696, 0, -2.488]} rotation={[3.134, 0.277, 2.858]} scale={1.991} />
            <mesh geometry={nodes.Object_66.geometry} material={materials['Material.001']} position={[0.746, 0, -2.436]} rotation={[-2.583, 1.398, 2.576]} scale={1.698} />
            <mesh geometry={nodes.Object_68.geometry} material={materials['Material.001']} position={[0.505, 0, -2.378]} rotation={[-2.208, 1.222, 2.331]} scale={1.372} />
            <mesh geometry={nodes.Object_70.geometry} material={materials['Material.001']} position={[0.491, 0, -2.326]} rotation={[2.862, -0.088, -3.138]} scale={1.959} />
            <mesh geometry={nodes.Object_72.geometry} material={materials['Material.001']} position={[0.513, 0, -2.28]} rotation={[0.447, -0.874, 0.32]} scale={1.478} />
            <mesh geometry={nodes.Object_74.geometry} material={materials['Material.001']} position={[0.443, 0, -2.345]} rotation={[-2.715, -1.441, -2.41]} scale={1.629} />
            <mesh geometry={nodes.Object_76.geometry} material={materials['Material.001']} position={[-2.759, 0, -1.337]} rotation={[3.023, 0.393, -3.062]} scale={1.789} />
            <mesh geometry={nodes.Object_78.geometry} material={materials['Material.001']} position={[-3.003, 0, -1.815]} rotation={[0.04, 0.21, 0.294]} scale={1.101} />
            <mesh geometry={nodes.Object_80.geometry} material={materials['Material.001']} position={[-2.868, 0, -1.826]} rotation={[-3.126, 1.317, 3.114]} scale={1.628} />
            <mesh geometry={nodes.Object_82.geometry} material={materials['Material.001']} position={[0.298, 0, -2.639]} rotation={[0.15, -0.966, 0.131]} scale={1.69} />
            <mesh geometry={nodes.Object_84.geometry} material={materials['Material.001']} position={[0.229, 0, -2.371]} rotation={[-2.98, 1.32, 3.009]} scale={1.519} />
            <mesh geometry={nodes.Object_86.geometry} material={materials['Material.001']} position={[0.187, 0, -2.293]} rotation={[-2.78, 0.52, 3.085]} scale={1.652} />
            <mesh geometry={nodes.Object_88.geometry} material={materials['Material.001']} position={[-2.805, 0, -1.874]} rotation={[-0.23, -1.402, 0.1]} scale={1.849} />
            <mesh geometry={nodes.Object_90.geometry} material={materials['Material.001']} position={[-2.744, 0, -1.841]} rotation={[0.119, 0.856, -0.143]} scale={1.661} />
            <mesh geometry={nodes.Object_92.geometry} material={materials['Material.001']} position={[-2.792, 0, -1.727]} rotation={[0.409, 1.186, -0.268]} scale={1.814} />
            <mesh geometry={nodes.Object_94.geometry} material={materials['Material.001']} position={[-2.748, 0, -1.528]} rotation={[0.246, -0.781, 0.063]} scale={1.966} />
            <mesh geometry={nodes.Object_96.geometry} material={materials['Material.001']} position={[-2.857, 0, -1.575]} rotation={[-1.671, 1.452, 1.65]} scale={1.923} />
            <mesh geometry={nodes.Object_98.geometry} material={materials['Material.001']} position={[-3.026, 0, -1.526]} rotation={[0.076, -1.037, 0.411]} scale={1.213} />
            <mesh geometry={nodes.Object_100.geometry} material={materials['Material.001']} position={[-2.845, 0, -2.068]} rotation={[-0.46, 0.777, 0.242]} scale={0.896} />
            <mesh geometry={nodes.Object_102.geometry} material={materials['Material.001']} position={[-3.027, 0, -1.987]} rotation={[2.994, 0.937, -2.939]} scale={1.857} />
            <mesh geometry={nodes.Object_104.geometry} material={materials['Material.001']} position={[-2.872, 0, -2.62]} rotation={[2.873, 0.352, -2.99]} scale={1.84} />
            <mesh geometry={nodes.Object_106.geometry} material={materials['Material.001']} position={[-3.024, 0, -2.476]} rotation={[2.887, 0.454, -2.878]} scale={1.067} />
            <mesh geometry={nodes.Object_108.geometry} material={materials['Material.001']} position={[-3.034, 0, 2.48]} rotation={[-3.123, -0.251, 2.946]} scale={1.298} />
            <mesh geometry={nodes.Object_110.geometry} material={materials['Material.001']} position={[-2.737, 0, -2.655]} rotation={[-0.435, 0.904, 0.381]} scale={1.634} />
            <mesh geometry={nodes.Object_112.geometry} material={materials['Material.001']} position={[-2.688, 0, -2.624]} rotation={[-0.317, 0.01, -0.124]} scale={1.968} />
            <mesh geometry={nodes.Object_114.geometry} material={materials['Material.001']} position={[-2.84, 0, 1.982]} rotation={[0.054, -0.181, 0.302]} scale={1.229} />
            <mesh geometry={nodes.Object_116.geometry} material={materials['Material.001']} position={[-2.813, 0, -2.359]} rotation={[-0.953, -1.37, -1.048]} scale={1.238} />
            <mesh geometry={nodes.Object_118.geometry} material={materials['Material.001']} position={[-2.933, 0, -2.332]} rotation={[0.026, 0.845, 0.046]} scale={1.896} />
            <mesh geometry={nodes.Object_120.geometry} material={materials['Material.001']} position={[-2.915, 0, -2.921]} rotation={[-3.137, -0.786, 3.112]} scale={1.889} />
            <mesh geometry={nodes.Object_122.geometry} material={materials['Material.001']} position={[-2.709, 0, -3.032]} rotation={[-3.088, -1.187, -3.114]} scale={1.331} />
            <mesh geometry={nodes.Object_124.geometry} material={materials['Material.001']} position={[-1.373, 0, -2.573]} rotation={[-1.62, -1.479, -1.719]} scale={1.434} />
            <mesh geometry={nodes.Object_126.geometry} material={materials['Material.001']} position={[-2.798, 0, -2.741]} rotation={[2.353, -1.168, 2.391]} scale={1.225} />
            <mesh geometry={nodes.Object_128.geometry} material={materials['Material.001']} position={[-2.9, 0, -2.784]} rotation={[-0.003, -0.001, 0.077]} scale={1.023} />
            <mesh geometry={nodes.Object_130.geometry} material={materials['Material.001']} position={[-2.995, 0, -2.751]} rotation={[-3.125, -0.45, 3.022]} scale={1.701} />
            <mesh geometry={nodes.Object_132.geometry} material={materials['Material.001']} position={[-2.533, 0, -2.948]} rotation={[3.125, -1.344, -2.931]} scale={1.3} />
            <mesh geometry={nodes.Object_134.geometry} material={materials['Material.001']} position={[-1.486, 0, -2.403]} rotation={[0.031, 0.946, 0.059]} scale={1.612} />
            <mesh geometry={nodes.Object_136.geometry} material={materials['Material.001']} position={[-1.886, 0, -2.471]} rotation={[-0.262, -0.293, -0.128]} scale={1.056} />
            <mesh geometry={nodes.Object_138.geometry} material={materials['Material.001']} position={[-1.667, 0, -2.487]} rotation={[-0.615, 1.039, 0.604]} scale={1.66} />
            <mesh geometry={nodes.Object_140.geometry} material={materials['Material.001']} position={[-1.606, 0, -2.314]} rotation={[-3.009, -1.247, -3.043]} scale={1.428} />
            <mesh geometry={nodes.Object_142.geometry} material={materials['Material.001']} position={[-1.522, 0, -2.321]} rotation={[1.681, -1.371, 1.729]} scale={1.969} />
            <mesh geometry={nodes.Object_144.geometry} material={materials['Material.001']} position={[-1.979, 0, -2.314]} rotation={[2.942, 0.936, -2.82]} scale={1.714} />
            <mesh geometry={nodes.Object_146.geometry} material={materials['Material.001']} position={[-2.114, 0, -2.306]} rotation={[-0.196, -0.722, -0.245]} scale={1.774} />
            <mesh geometry={nodes.Object_148.geometry} material={materials['Material.001']} position={[-2.493, 0, -2.577]} rotation={[0.229, 0.893, -0.288]} scale={1.903} />
            <mesh geometry={nodes.Object_150.geometry} material={materials['Material.001']} position={[-2.61, 0, -2.547]} rotation={[0.474, -1.106, 0.422]} scale={0.977} />
            <mesh geometry={nodes.Object_152.geometry} material={materials['Material.001']} position={[-2.354, 0, -2.632]} rotation={[0.102, -0.188, 0.216]} scale={1.019} />
            <mesh geometry={nodes.Object_154.geometry} material={materials['Material.001']} position={[-2.365, 0, -2.565]} rotation={[0.035, 0.183, 0.311]} scale={1.201} />
            <mesh geometry={nodes.Object_156.geometry} material={materials['Material.001']} position={[-2.376, 0, -2.446]} rotation={[-0.237, -0.88, -0.258]} scale={0.954} />
            <mesh geometry={nodes.Object_158.geometry} material={materials['Material.001']} position={[-2.33, 0, -2.393]} rotation={[-3.047, -1.216, -2.804]} scale={1.183} />
            <mesh geometry={nodes.Object_160.geometry} material={materials['Material.001']} position={[-2.642, 0, -2.307]} rotation={[3.106, 0.066, -3.14]} scale={0.992} />
            <mesh geometry={nodes.Object_162.geometry} material={materials['Material.001']} position={[2.32, 0, -2.219]} rotation={[2.559, -1.069, 2.681]} scale={1.788} />
            <mesh geometry={nodes.Object_164.geometry} material={materials['Material.001']} position={[2.329, 0, -2.161]} rotation={[-0.006, -1.283, -0.006]} scale={1.192} />
            <mesh geometry={nodes.Object_166.geometry} material={materials['Material.001']} position={[2.564, 0, -2.242]} rotation={[0, 0.52, 0.019]} scale={1.515} />
            <mesh geometry={nodes.Object_168.geometry} material={materials['Material.001']} position={[2.615, 0, -2.068]} rotation={[3.135, 0.264, 2.899]} scale={1.29} />
            <mesh geometry={nodes.Object_170.geometry} material={materials['Material.001']} position={[2.293, 0, -1.92]} rotation={[-3.063, -0.425, 2.837]} scale={1.825} />
            <mesh geometry={nodes.Object_172.geometry} material={materials['Material.001']} position={[2.238, 0, -2.214]} rotation={[-3.055, 0.772, 2.847]} scale={1.207} />
            <mesh geometry={nodes.Object_174.geometry} material={materials['Material.001']} position={[2.195, 0, -2.138]} rotation={[-3.094, 0.514, -3.081]} scale={1.662} />
            <mesh geometry={nodes.Object_176.geometry} material={materials['Material.001']} position={[2.248, 0, -2.049]} rotation={[-3.019, 0.462, 2.807]} scale={1.417} />
            <mesh geometry={nodes.Object_178.geometry} material={materials['Material.001']} position={[2.251, 0, -1.986]} rotation={[0.044, 1.11, -0.028]} scale={1.1} />
            <mesh geometry={nodes.Object_180.geometry} material={materials['Material.001']} position={[1.942, 0, -1.937]} rotation={[2.969, 0.418, -2.964]} scale={1.897} />
            <mesh geometry={nodes.Object_182.geometry} material={materials['Material.001']} position={[1.806, 0, -2.117]} rotation={[0.207, 0.813, -0.232]} scale={1.075} />
            <mesh geometry={nodes.Object_184.geometry} material={materials['Material.001']} position={[1.728, 0, -2.019]} rotation={[-3.078, -0.421, 2.893]} scale={1.516} />
            <mesh geometry={nodes.Object_186.geometry} material={materials['Material.001']} position={[1.395, 0, -2.201]} rotation={[0.022, 0.28, -0.054]} scale={0.849} />
            <mesh geometry={nodes.Object_188.geometry} material={materials['Material.001']} position={[1.46, 0, -2.216]} rotation={[2.924, -0.821, 2.958]} scale={1.215} />
            <mesh geometry={nodes.Object_190.geometry} material={materials['Material.001']} position={[1.51, 0, -2.165]} rotation={[-0.277, 0.918, 0.245]} scale={0.932} />
            <mesh geometry={nodes.Object_192.geometry} material={materials['Material.001']} position={[1.239, 0, -2.034]} rotation={[0.188, 1.062, -0.4]} scale={0.859} />
            <mesh geometry={nodes.Object_194.geometry} material={materials['Material.001']} position={[1.319, 0, -2.054]} rotation={[0.264, 0.563, -0.252]} scale={1.225} />
            <mesh geometry={nodes.Object_196.geometry} material={materials['Material.001']} position={[1.279, 0, -1.943]} rotation={[0.443, 1.124, -0.247]} scale={1.966} />
            <mesh geometry={nodes.Object_198.geometry} material={materials['Material.001']} position={[1.213, 0, -1.931]} rotation={[0.082, 0.84, -0.294]} scale={1.546} />
            <mesh geometry={nodes.Object_200.geometry} material={materials['Material.001']} position={[1.102, 0, -2.251]} rotation={[-0.001, -0.432, -0.001]} scale={1.195} />
            <mesh geometry={nodes.Object_202.geometry} material={materials['Material.001']} position={[1.063, 0, -2.115]} rotation={[0.218, 1.414, -0.495]} scale={1.398} />
            <mesh geometry={nodes.Object_204.geometry} material={materials['Material.001']} position={[0.963, 0, -2.096]} rotation={[0.102, -1.25, 0.22]} scale={1.372} />
            <mesh geometry={nodes.Object_206.geometry} material={materials['Material.001']} position={[0.969, 0, -2.021]} rotation={[-0.006, -0.326, 0.027]} scale={1.851} />
            <mesh geometry={nodes.Object_208.geometry} material={materials['Material.001']} position={[0.994, 0, -1.912]} rotation={[-0.034, 1.151, 0.024]} scale={1.305} />
            <mesh geometry={nodes.Object_210.geometry} material={materials['Material.001']} position={[0.538, 0, -2.175]} rotation={[-2.084, -1.493, -2.119]} scale={1.737} />
            <mesh geometry={nodes.Object_212.geometry} material={materials['Material.001']} position={[0.732, 0, -2.231]} rotation={[2.448, 1.292, -2.372]} scale={1.51} />
            <mesh geometry={nodes.Object_214.geometry} material={materials['Material.001']} position={[0.605, 0, -1.93]} rotation={[-2.964, 1.127, 2.981]} scale={1.588} />
            <mesh geometry={nodes.Object_216.geometry} material={materials['Material.001']} position={[-2.296, 0, -2.979]} rotation={[3.032, -0.422, -3.122]} scale={1.378} />
            <mesh geometry={nodes.Object_218.geometry} material={materials['Material.001']} position={[0.439, 0, -1.922]} rotation={[0.027, -0.796, 0.022]} scale={1.496} />
            <mesh geometry={nodes.Object_220.geometry} material={materials['Material.001']} position={[-2.417, 0, -2.922]} rotation={[-3.118, 0.284, 3.103]} scale={1.985} />
            <mesh geometry={nodes.Object_222.geometry} material={materials['Material.001']} position={[0.201, 0, -2.203]} rotation={[-1.06, -1.43, -0.937]} scale={1.311} />
            <mesh geometry={nodes.Object_224.geometry} material={materials['Material.001']} position={[0.369, 0, -2.207]} rotation={[2.062, -1.43, 2.088]} scale={1.001} />
            <mesh geometry={nodes.Object_226.geometry} material={materials['Material.001']} position={[0.306, 0, -2.04]} rotation={[0.241, 1.237, -0.047]} scale={1.694} />
            <mesh geometry={nodes.Object_228.geometry} material={materials['Material.001']} position={[-2.507, 0, -2.821]} rotation={[1.176, -1.5, 1.141]} scale={1.261} />
            <mesh geometry={nodes.Object_230.geometry} material={materials['Material.001']} position={[-2.238, 0, -2.991]} rotation={[3.016, 0.95, -2.988]} scale={1.074} />
            <mesh geometry={nodes.Object_232.geometry} material={materials['Material.001']} position={[-2.218, 0, -2.904]} rotation={[-0.009, -0.07, 0]} scale={0.866} />
            <mesh geometry={nodes.Object_234.geometry} material={materials['Material.001']} position={[-1.987, 0, -2.867]} rotation={[-3.11, -1.346, -2.884]} scale={1.278} />
            <mesh geometry={nodes.Object_236.geometry} material={materials['Material.001']} position={[-2.071, 0, -2.896]} rotation={[-0.273, 0.578, 0.171]} scale={0.953} />
            <mesh geometry={nodes.Object_238.geometry} material={materials['Material.001']} position={[-2.008, 0, -2.769]} rotation={[-0.635, -1.098, -0.781]} scale={1.553} />
            <mesh geometry={nodes.Object_240.geometry} material={materials['Material.001']} position={[-1.973, 0, -2.809]} rotation={[-3.017, -0.985, -3.126]} scale={1.121} />
            <mesh geometry={nodes.Object_242.geometry} material={materials['Material.001']} position={[-2.742, 0, 1.9]} rotation={[-2.608, -1.359, -2.525]} scale={1.906} />
            <mesh geometry={nodes.Object_244.geometry} material={materials['Material.001']} position={[-2.161, 0, -2.751]} rotation={[2.631, -1.136, 2.62]} scale={0.913} />
            <mesh geometry={nodes.Object_246.geometry} material={materials['Material.001']} position={[-1.553, 0, -2.88]} rotation={[3.139, 0.067, 3.125]} scale={1.357} />
            <mesh geometry={nodes.Object_248.geometry} material={materials['Material.001']} position={[-1.566, 0, -2.706]} rotation={[-3.138, -1.25, -3.138]} scale={1.446} />
            <mesh geometry={nodes.Object_250.geometry} material={materials['Material.001']} position={[-1.531, 0, -2.66]} rotation={[1.633, 1.435, -1.382]} scale={1.333} />
            <mesh geometry={nodes.Object_252.geometry} material={materials['Material.001']} position={[-1.735, 0, -2.808]} rotation={[-2.984, -0.8, -2.793]} scale={1.13} />
            <mesh geometry={nodes.Object_254.geometry} material={materials['Material.001']} position={[-1.727, 0, -2.672]} rotation={[-2.92, 0.16, -3.125]} scale={1.288} />
            <mesh geometry={nodes.Object_256.geometry} material={materials['Material.001']} position={[-1.814, 0, -2.747]} rotation={[0.317, 0.222, -0.212]} scale={1.16} />
            <mesh geometry={nodes.Object_258.geometry} material={materials['Material.001']} position={[-1.507, 0, -2.972]} rotation={[-2.885, 0.803, 3.012]} scale={1.407} />
            <mesh geometry={nodes.Object_260.geometry} material={materials['Material.001']} position={[-3.035, 0, 1.559]} rotation={[3.074, 1.223, 3.078]} scale={1.118} />
            <mesh geometry={nodes.Object_262.geometry} material={materials['Material.001']} position={[-2.929, 0, 1.556]} rotation={[2.922, -1.304, 2.91]} scale={1.312} />
            <mesh geometry={nodes.Object_264.geometry} material={materials['Material.001']} position={[-2.892, 0, 1.696]} rotation={[-3.038, -0.113, -2.834]} scale={0.842} />
            <mesh geometry={nodes.Object_266.geometry} material={materials['Material.001']} position={[-1.419, 0, -2.219]} rotation={[-0.007, -0.324, -0.011]} scale={1.009} />
            <mesh geometry={nodes.Object_268.geometry} material={materials['Material.001']} position={[-1.381, 0, -2.098]} rotation={[-2.91, -0.858, 3.035]} scale={1.075} />
            <mesh geometry={nodes.Object_270.geometry} material={materials['Material.001']} position={[-1.457, 0, -2.148]} rotation={[3.137, -1.035, -3.031]} scale={0.985} />
            <mesh geometry={nodes.Object_272.geometry} material={materials['Material.001']} position={[-3.023, 0, 1.628]} rotation={[-0.171, -0.061, -0.03]} scale={1.54} />
            <mesh geometry={nodes.Object_274.geometry} material={materials['Material.001']} position={[-2.7, 0, 1.781]} rotation={[0.783, -1.099, 0.666]} scale={1.928} />
            <mesh geometry={nodes.Object_276.geometry} material={materials['Material.001']} position={[-1.493, 0, -1.958]} rotation={[0.788, -1.216, 0.764]} scale={1.012} />
            <mesh geometry={nodes.Object_278.geometry} material={materials['Material.001']} position={[-1.551, 0, -2.139]} rotation={[-0.152, -0.155, -0.147]} scale={1.367} />
            <mesh geometry={nodes.Object_280.geometry} material={materials['Material.001']} position={[-1.573, 0, -1.983]} rotation={[-3.003, -1.419, -3.071]} scale={1.345} />
            <mesh geometry={nodes.Object_282.geometry} material={materials['Material.001']} position={[-1.704, 0, -1.962]} rotation={[0.109, -0.892, 0.392]} scale={1.095} />
            <mesh geometry={nodes.Object_284.geometry} material={materials['Material.001']} position={[-1.79, 0, -2.058]} rotation={[-2.841, -0.888, -2.793]} scale={1.581} />
            <mesh geometry={nodes.Object_286.geometry} material={materials['Material.001']} position={[-2.144, 0, -2.232]} rotation={[-2.931, 0.348, -3.099]} scale={1.072} />
            <mesh geometry={nodes.Object_288.geometry} material={materials['Material.001']} position={[-2.133, 0, -2.123]} rotation={[-2.9, -1.055, 3.037]} scale={1.593} />
            <mesh geometry={nodes.Object_290.geometry} material={materials['Material.001']} position={[-2.068, 0, -2.198]} rotation={[-2.952, 0.263, -3.092]} scale={1.08} />
            <mesh geometry={nodes.Object_292.geometry} material={materials['Material.001']} position={[-2.519, 0, -2.197]} rotation={[0.015, 0.217, 0.122]} scale={1.49} />
            <mesh geometry={nodes.Object_294.geometry} material={materials['Material.001']} position={[-2.345, 0, -2.164]} rotation={[2.749, -0.683, 2.932]} scale={1.171} />
            <mesh geometry={nodes.Object_296.geometry} material={materials['Material.001']} position={[2.577, 0, -1.883]} rotation={[0.048, 0.409, 0.132]} scale={1.382} />
            <mesh geometry={nodes.Object_298.geometry} material={materials['Material.001']} position={[2.54, 0, -1.73]} rotation={[-3.002, -0.288, 2.943]} scale={1.342} />
            <mesh geometry={nodes.Object_300.geometry} material={materials['Material.001']} position={[2.194, 0, -1.848]} rotation={[-2.909, -0.408, -2.93]} scale={0.889} />
            <mesh geometry={nodes.Object_302.geometry} material={materials['Material.001']} position={[2.106, 0, -1.676]} rotation={[-1.401, -1.49, -1.234]} scale={1.17} />
            <mesh geometry={nodes.Object_304.geometry} material={materials['Material.001']} position={[1.648, 0, -1.743]} rotation={[-3.115, 0.131, 3.013]} scale={1.567} />
            <mesh geometry={nodes.Object_306.geometry} material={materials['Material.001']} position={[1.736, 0, -1.573]} rotation={[-0.005, -1.157, 0.135]} scale={1.474} />
            <mesh geometry={nodes.Object_308.geometry} material={materials['Material.001']} position={[1.688, 0, -1.556]} rotation={[0.306, -0.478, -0.055]} scale={1.259} />
            <mesh geometry={nodes.Object_310.geometry} material={materials['Material.001']} position={[1.24, 0, -1.887]} rotation={[1.011, -1.389, 0.936]} scale={1.957} />
            <mesh geometry={nodes.Object_312.geometry} material={materials['Material.001']} position={[1.326, 0, -1.863]} rotation={[3.012, -1.55, 3.011]} scale={1.086} />
            <mesh geometry={nodes.Object_314.geometry} material={materials['Material.001']} position={[1.258, 0, -1.73]} rotation={[-2.715, -1.371, -2.556]} scale={1.916} />
            <mesh geometry={nodes.Object_316.geometry} material={materials['Material.001']} position={[0.929, 0, -1.867]} rotation={[0.113, 0.454, -0.279]} scale={1.609} />
            <mesh geometry={nodes.Object_318.geometry} material={materials['Material.001']} position={[1.013, 0, -1.748]} rotation={[-3.084, -1.077, 3.135]} scale={1.027} />
            <mesh geometry={nodes.Object_320.geometry} material={materials['Material.001']} position={[1.038, 0, -1.684]} rotation={[-3.141, 1.29, 2.808]} scale={1.03} />
            <mesh geometry={nodes.Object_322.geometry} material={materials['Material.001']} position={[0.795, 0, -1.635]} rotation={[0.818, 1.316, -0.96]} scale={1.532} />
            <mesh geometry={nodes.Object_324.geometry} material={materials['Material.001']} position={[0.701, 0, -1.798]} rotation={[-3.094, -0.226, 3.086]} scale={1.615} />
            <mesh geometry={nodes.Object_326.geometry} material={materials['Material.001']} position={[0.711, 0, -1.571]} rotation={[-2.822, -1.35, -2.871]} scale={0.889} />
            <mesh geometry={nodes.Object_328.geometry} material={materials['Material.001']} position={[-2.68, 0, 1.894]} rotation={[0.363, 1.12, -0.038]} scale={0.929} />
            <mesh geometry={nodes.Object_330.geometry} material={materials['Material.001']} position={[-2.989, 0, 2.327]} rotation={[-3.118, -0.547, 3.032]} scale={1.077} />
            <mesh geometry={nodes.Object_332.geometry} material={materials['Material.001']} position={[-2.94, 0, 1.809]} rotation={[2.871, -0.079, -2.991]} scale={1.77} />
            <mesh geometry={nodes.Object_334.geometry} material={materials['Material.001']} position={[-2.826, 0, 1.341]} rotation={[2.434, 1.517, -2.457]} scale={0.922} />
            <mesh geometry={nodes.Object_336.geometry} material={materials['Material.001']} position={[-2.736, 0, 2.472]} rotation={[-2.28, 1.397, 2.26]} scale={1.54} />
            <mesh geometry={nodes.Object_338.geometry} material={materials['Material.001']} position={[-2.826, 0, 0.849]} rotation={[2.953, 0.379, -2.973]} scale={1.052} />
            <mesh geometry={nodes.Object_340.geometry} material={materials['Material.001']} position={[-2.835, 0, 0.435]} rotation={[3.131, 0.403, 2.865]} scale={1.34} />
            <mesh geometry={nodes.Object_342.geometry} material={materials['Material.001']} position={[-0.259, 0, -2.934]} rotation={[-0.219, -0.137, -0.119]} scale={1.737} />
            <mesh geometry={nodes.Object_344.geometry} material={materials['Material.001']} position={[-0.003, 0, -2.856]} rotation={[3.136, 0.812, 2.814]} scale={1.484} />
            <mesh geometry={nodes.Object_346.geometry} material={materials['Material.001']} position={[-2.702, 0, 0.554]} rotation={[0.006, 0.202, 0.072]} scale={1.263} />
            <mesh geometry={nodes.Object_348.geometry} material={materials['Material.001']} position={[-2.779, 0, 0.723]} rotation={[2.367, 1.342, -2.209]} scale={1.049} />
            <mesh geometry={nodes.Object_350.geometry} material={materials['Material.001']} position={[-2.975, 0, 0.67]} rotation={[-2.954, 1.167, 2.976]} scale={1.409} />
            <mesh geometry={nodes.Object_352.geometry} material={materials['Material.001']} position={[0.079, 0, -2.88]} rotation={[2.919, 0.127, -3.084]} scale={0.924} />
            <mesh geometry={nodes.Object_354.geometry} material={materials['Material.001']} position={[0.195, 0, -2.984]} rotation={[-3.054, 0.318, -2.989]} scale={1.972} />
            <mesh geometry={nodes.Object_356.geometry} material={materials['Material.001']} position={[0.162, 0, -2.724]} rotation={[0.083, -0.779, 0.238]} scale={1.289} />
            <mesh geometry={nodes.Object_358.geometry} material={materials['Material.001']} position={[0.409, 0, -2.886]} rotation={[0.053, -0.456, 0.307]} scale={1.849} />
            <mesh geometry={nodes.Object_360.geometry} material={materials['Material.001']} position={[-2.351, 0, -1.824]} rotation={[-0.004, -0.397, 0.084]} scale={1.406} />
            <mesh geometry={nodes.Object_362.geometry} material={materials['Material.001']} position={[2.536, 0, -1.434]} rotation={[-3.103, 0.879, 2.817]} scale={1.455} />
            <mesh geometry={nodes.Object_364.geometry} material={materials['Material.001']} position={[2.608, 0, -1.216]} rotation={[-0.118, -1.142, 0.017]} scale={1.512} />
            <mesh geometry={nodes.Object_366.geometry} material={materials['Material.001']} position={[2.482, 0, -1.165]} rotation={[-0.263, -0.27, -0.271]} scale={0.976} />
            <mesh geometry={nodes.Object_368.geometry} material={materials['Material.001']} position={[2.421, 0, -1.146]} rotation={[-2.733, -1.131, -2.977]} scale={1.797} />
            <mesh geometry={nodes.Object_370.geometry} material={materials['Material.001']} position={[2.332, 0, -1.185]} rotation={[0.029, -0.831, -0.019]} scale={1.465} />
            <mesh geometry={nodes.Object_372.geometry} material={materials['Material.001']} position={[1.912, 0, -1.35]} rotation={[-2.862, -0.737, -2.829]} scale={1.19} />
            <mesh geometry={nodes.Object_374.geometry} material={materials['Material.001']} position={[0.527, 0, -2.669]} rotation={[-0.399, -1.375, -0.538]} scale={1.452} />
            <mesh geometry={nodes.Object_376.geometry} material={materials['Material.001']} position={[2.255, 0, -1.392]} rotation={[0.08, 0.281, -0.044]} scale={1.073} />
            <mesh geometry={nodes.Object_378.geometry} material={materials['Material.001']} position={[2.139, 0, -1.276]} rotation={[-2.758, 0.704, 3.061]} scale={1.918} />
            <mesh geometry={nodes.Object_380.geometry} material={materials['Material.001']} position={[2.229, 0, -1.254]} rotation={[0.173, -0.529, 0.072]} scale={1.532} />
            <mesh geometry={nodes.Object_382.geometry} material={materials['Material.001']} position={[1.916, 0, -1.252]} rotation={[-2.509, 1.034, 2.646]} scale={1.663} />
            <mesh geometry={nodes.Object_384.geometry} material={materials['Material.001']} position={[1.543, 0, -1.395]} rotation={[-2.985, -0.223, 2.873]} scale={1.207} />
            <mesh geometry={nodes.Object_386.geometry} material={materials['Material.001']} position={[1.851, 0, -1.457]} rotation={[-0.081, 0.722, 0.041]} scale={1.521} />
            <mesh geometry={nodes.Object_388.geometry} material={materials['Material.001']} position={[1.618, 0, -1.287]} rotation={[-3.026, 0.528, 3.084]} scale={1.163} />
            <mesh geometry={nodes.Object_390.geometry} material={materials['Material.001']} position={[1.534, 0, -1.218]} rotation={[0.115, -0.022, -0.099]} scale={1.701} />
            <mesh geometry={nodes.Object_392.geometry} material={materials['Material.001']} position={[1.507, 0, -1.506]} rotation={[-0.228, -0.967, -0.355]} scale={1.309} />
            <mesh geometry={nodes.Object_394.geometry} material={materials['Material.001']} position={[1.498, 0, -1.18]} rotation={[-2.821, 0.429, 3.127]} scale={1.809} />
            <mesh geometry={nodes.Object_396.geometry} material={materials['Material.001']} position={[1.162, 0, -1.211]} rotation={[0.007, -0.291, -0.021]} scale={1.822} />
            <mesh geometry={nodes.Object_398.geometry} material={materials['Material.001']} position={[0.404, 0, -2.748]} rotation={[-0.364, 0.797, 0.168]} scale={1.015} />
            <mesh geometry={nodes.Object_400.geometry} material={materials['Material.001']} position={[1.118, 0, -1.413]} rotation={[0.002, 0.094, 0.179]} scale={0.95} />
            <mesh geometry={nodes.Object_402.geometry} material={materials['Material.001']} position={[1.095, 0, -1.366]} rotation={[2.82, 1.454, -2.808]} scale={1.27} />
            <mesh geometry={nodes.Object_404.geometry} material={materials['Material.001']} position={[0.797, 0, -3.025]} rotation={[-2.828, 0.249, -3.105]} scale={1.222} />
            <mesh geometry={nodes.Object_406.geometry} material={materials['Material.001']} position={[1.103, 0, -2.985]} rotation={[2.739, 1.05, -2.659]} scale={1.343} />
            <mesh geometry={nodes.Object_408.geometry} material={materials['Material.001']} position={[0.993, 0, -2.903]} rotation={[0.008, -0.234, 0.301]} scale={1.889} />
            <mesh geometry={nodes.Object_410.geometry} material={materials['Material.001']} position={[1.181, 0, -2.983]} rotation={[1.97, 1.447, -1.877]} scale={0.961} />
            <mesh geometry={nodes.Object_412.geometry} material={materials['Material.001']} position={[1.38, 0, -2.909]} rotation={[2.905, -0.26, -3.118]} scale={1.904} />
            <mesh geometry={nodes.Object_414.geometry} material={materials['Material.001']} position={[1.336, 0, -2.852]} rotation={[-3.138, -0.656, -3.098]} scale={1.159} />
            <mesh geometry={nodes.Object_416.geometry} material={materials['Material.001']} position={[1.452, 0, -2.672]} rotation={[-3.118, -0.82, -3.119]} scale={1.926} />
            <mesh geometry={nodes.Object_418.geometry} material={materials['Material.001']} position={[1.267, 0, -2.696]} rotation={[0.022, -1.388, 0.023]} scale={1.026} />
            <mesh geometry={nodes.Object_420.geometry} material={materials['Material.001']} position={[1.626, 0, -2.982]} rotation={[0.016, -0.052, 0.173]} scale={0.941} />
            <mesh geometry={nodes.Object_422.geometry} material={materials['Material.001']} position={[1.731, 0, -3.035]} rotation={[0.004, 0.596, -0.022]} scale={0.847} />
            <mesh geometry={nodes.Object_424.geometry} material={materials['Material.001']} position={[1.814, 0, -2.859]} rotation={[0.349, 0.612, -0.392]} scale={1.013} />
            <mesh geometry={nodes.Object_426.geometry} material={materials['Material.001']} position={[1.762, 0, -2.804]} rotation={[-2.87, -1.005, -2.613]} scale={1.06} />
            <mesh geometry={nodes.Object_428.geometry} material={materials['Material.001']} position={[-2.829, 0, 0.021]} rotation={[0.236, -0.394, -0.055]} scale={1.141} />
            <mesh geometry={nodes.Object_430.geometry} material={materials['Material.001']} position={[1.871, 0, -2.75]} rotation={[-2.902, -0.843, 3.12]} scale={1.182} />
            <mesh geometry={nodes.Object_432.geometry} material={materials['Material.001']} position={[1.742, 0, -2.659]} rotation={[-3.115, 0.746, 3.132]} scale={1.596} />
            <mesh geometry={nodes.Object_434.geometry} material={materials['Material.001']} position={[1.654, 0, -2.673]} rotation={[3.093, -0.542, 3.109]} scale={1.823} />
            <mesh geometry={nodes.Object_436.geometry} material={materials['Material.001']} position={[2.012, 0, -3.027]} rotation={[-0.029, -1.087, 0.014]} scale={1.352} />
            <mesh geometry={nodes.Object_438.geometry} material={materials['Material.001']} position={[2.251, 0, -2.982]} rotation={[2.278, -1.381, 2.272]} scale={1.179} />
            <mesh geometry={nodes.Object_440.geometry} material={materials['Material.001']} position={[2.091, 0, -2.698]} rotation={[-0.171, 0.808, 0.113]} scale={1.963} />
            <mesh geometry={nodes.Object_442.geometry} material={materials['Material.001']} position={[1.906, 0, -2.668]} rotation={[0.094, 1.239, -0.105]} scale={0.96} />
            <mesh geometry={nodes.Object_444.geometry} material={materials['Material.001']} position={[2.643, -0.009, -3.489]} rotation={[0, 1.518, 0]} scale={1.172} />
            <mesh geometry={nodes.Object_446.geometry} material={materials['Material.001']} position={[-3.081, 0.533, -2.168]} rotation={[-Math.PI, 1.519, -Math.PI]} scale={[0.337, 0.357, 0.337]} />
            <mesh geometry={nodes.Object_448.geometry} material={materials['Material.001']} position={[3.144, 0.533, -2.266]} rotation={[-Math.PI, 1.253, -Math.PI]} scale={[0.337, 0.357, 0.337]} />
            <mesh geometry={nodes.Object_450.geometry} material={materials['Material.001']} position={[0.07, 0.014, -2.741]} rotation={[-Math.PI, 0.508, -Math.PI]} scale={[0.033, 0.168, 0.033]} />
            <mesh geometry={nodes.Object_452.geometry} material={materials['Material.001']} position={[0.537, 0.014, -2.741]} rotation={[-Math.PI, 1.162, -Math.PI]} scale={[0.044, 0.224, 0.044]} />
            <mesh geometry={nodes.Object_454.geometry} material={materials['Material.001']} position={[0.224, 0.014, -2.741]} rotation={[0, -0.072, 0]} scale={[0.044, 0.224, 0.044]} />
            <mesh geometry={nodes.Object_456.geometry} material={materials['Material.001']} position={[1.807, 0.014, -2.122]} scale={[0.071, 0.357, 0.071]} />
            <mesh geometry={nodes.Object_458.geometry} material={materials['Material.001']} position={[1.704, 0.014, 0.06]} rotation={[-0.001, 0.032, 0.039]} scale={[0.068, 0.357, 0.068]} />
            <mesh geometry={nodes.Object_460.geometry} material={materials['Material.001']} position={[1.578, 0.014, -1.597]} rotation={[0.043, -0.403, 0.108]} scale={[0.068, 0.357, 0.068]} />
            <mesh geometry={nodes.Object_462.geometry} material={materials['Material.001']} position={[0.696, 0.014, -2.155]} rotation={[0, 0.951, 0]} scale={[0.068, 0.357, 0.068]} />
            <mesh geometry={nodes.Object_464.geometry} material={materials['Material.001']} position={[1.055, 0.014, -1.796]} scale={[0.071, 0.357, 0.071]} />
            <mesh geometry={nodes.Object_466.geometry} material={materials['Material.001']} position={[2.31, 0, -1.06]} rotation={[0.228, 1.101, -0.264]} scale={1.9} />
            <mesh geometry={nodes.Object_468.geometry} material={materials['Material.001']} position={[2.353, 0, -1.016]} rotation={[-3.135, 0.051, 2.967]} scale={1.848} />
            <mesh geometry={nodes.Object_470.geometry} material={materials['Material.001']} position={[2.592, 0, -0.992]} rotation={[-3.1, 0.199, 3.067]} scale={1.406} />
            <mesh geometry={nodes.Object_472.geometry} material={materials['Material.001']} position={[2.644, 0, -0.894]} rotation={[-2.375, -1.248, -2.673]} scale={1.98} />
            <mesh geometry={nodes.Object_474.geometry} material={materials['Material.001']} position={[2.581, 0, -0.772]} rotation={[-1.971, 1.247, 2.082]} scale={1.317} />
            <mesh geometry={nodes.Object_476.geometry} material={materials['Material.001']} position={[2.342, 0, -0.904]} rotation={[-3.024, -1.146, -3.064]} scale={1.534} />
            <mesh geometry={nodes.Object_478.geometry} material={materials['Material.001']} position={[1.955, 0, -1.05]} rotation={[3.002, 0.15, -3.008]} scale={1.506} />
            <mesh geometry={nodes.Object_480.geometry} material={materials['Material.001']} position={[2.05, 0, -1.019]} rotation={[-2.815, -0.704, -2.79]} scale={1.757} />
            <mesh geometry={nodes.Object_482.geometry} material={materials['Material.001']} position={[2.241, 0, -0.865]} rotation={[-0.047, -0.271, -0.058]} scale={1.484} />
            <mesh geometry={nodes.Object_484.geometry} material={materials['Material.001']} position={[1.992, 0, -0.832]} rotation={[2.982, 0.081, -3.114]} scale={1.853} />
            <mesh geometry={nodes.Object_486.geometry} material={materials['Material.001']} position={[1.82, 0, -1.039]} rotation={[2.919, 0.088, -3.048]} scale={1.115} />
            <mesh geometry={nodes.Object_488.geometry} material={materials['Material.001']} position={[1.646, 0, -0.843]} rotation={[0.066, -0.158, 0.165]} scale={0.943} />
            <mesh geometry={nodes.Object_490.geometry} material={materials['Material.001']} position={[1.565, 0, -0.783]} rotation={[-3.115, -0.395, -3.124]} scale={1.989} />
            <mesh geometry={nodes.Object_492.geometry} material={materials['Material.001']} position={[2.375, 0, -2.939]} rotation={[-3.119, 0.618, 3.091]} scale={1.489} />
            <mesh geometry={nodes.Object_494.geometry} material={materials['Material.001']} position={[1.324, 0, -1.068]} rotation={[1.24, -1.324, 1.214]} scale={1.784} />
            <mesh geometry={nodes.Object_496.geometry} material={materials['Material.001']} position={[1.376, 0, -1.053]} rotation={[-0.428, 0.97, 0.406]} scale={1.899} />
            <mesh geometry={nodes.Object_498.geometry} material={materials['Material.001']} position={[1.471, 0, -0.96]} rotation={[2.897, -0.472, 3.103]} scale={0.994} />
            <mesh geometry={nodes.Object_500.geometry} material={materials['Material.001']} position={[1.274, 0, -0.881]} rotation={[-3.011, 0.538, 2.769]} scale={1.217} />
            <mesh geometry={nodes.Object_502.geometry} material={materials['Material.001']} position={[2.318, 0, -2.858]} rotation={[0.229, 0.628, -0.296]} scale={0.965} />
            <mesh geometry={nodes.Object_504.geometry} material={materials['Material.001']} position={[2.575, 0, -2.964]} rotation={[-0.445, 0.985, 0.34]} scale={1.23} />
            <mesh geometry={nodes.Object_506.geometry} material={materials['Material.001']} position={[2.37, 0, -2.698]} rotation={[-3.011, 1.04, 2.765]} scale={1.333} />
            <mesh geometry={nodes.Object_508.geometry} material={materials['Material.001']} position={[2.797, 0, -2.949]} rotation={[-0.009, -0.226, 0.161]} scale={1.665} />
            <mesh geometry={nodes.Object_510.geometry} material={materials['Material.001']} position={[3.037, 0, -2.817]} rotation={[-3.02, 0.211, 3.14]} scale={1.764} />
            <mesh geometry={nodes.Object_512.geometry} material={materials['Material.001']} position={[3.026, 0, -2.699]} rotation={[-1.263, -1.43, -1.51]} scale={1.786} />
            <mesh geometry={nodes.Object_514.geometry} material={materials['Material.001']} position={[2.878, 0, -2.711]} rotation={[0.141, -0.732, 0.039]} scale={1.251} />
            <mesh geometry={nodes.Object_516.geometry} material={materials['Material.001']} position={[2.937, 0, -2.677]} rotation={[-0.249, -0.121, -0.182]} scale={1.943} />
            <mesh geometry={nodes.Object_518.geometry} material={materials['Material.001']} position={[2.824, 0, -2.74]} rotation={[-2.393, -1.425, -2.147]} scale={1.786} />
            <mesh geometry={nodes.Object_520.geometry} material={materials['Material.001']} position={[2.88, 0, -2.628]} rotation={[-3.096, 0.362, -3.053]} scale={1.296} />
            <mesh geometry={nodes.Object_522.geometry} material={materials['Material.001']} position={[-2.992, 0, 0.34]} rotation={[2.224, 1.272, -2.05]} scale={1.737} />
            <mesh geometry={nodes.Object_524.geometry} material={materials['Material.001']} position={[3.01, 0, -2.468]} rotation={[0.261, -0.506, 0.009]} scale={1.987} />
            <mesh geometry={nodes.Object_526.geometry} material={materials['Material.001']} position={[3.011, 0, -2.386]} rotation={[-1.526, -1.426, -1.775]} scale={1.031} />
            <mesh geometry={nodes.Object_528.geometry} material={materials['Material.001']} position={[2.973, 0, -2.341]} rotation={[3.139, -0.031, -3.139]} scale={1.08} />
            <mesh geometry={nodes.Object_530.geometry} material={materials['Material.001']} position={[2.845, 0, -2.435]} rotation={[0, 0.657, 0]} scale={0.851} />
            <mesh geometry={nodes.Object_532.geometry} material={materials['Material.001']} position={[2.787, 0, -2.339]} rotation={[-2.968, -1.465, -2.678]} scale={0.89} />
            <mesh geometry={nodes.Object_534.geometry} material={materials['Material.001']} position={[3.015, 0, -2.276]} rotation={[-3.032, 1.399, 2.969]} scale={1.565} />
            <mesh geometry={nodes.Object_536.geometry} material={materials['Material.001']} position={[3.016, 0, -2.071]} rotation={[-0.085, 1.378, -0.107]} scale={1.609} />
            <mesh geometry={nodes.Object_538.geometry} material={materials['Material.001']} position={[-2.93, 0, -0.329]} rotation={[-0.108, -0.612, -0.078]} scale={1.248} />
            <mesh geometry={nodes.Object_540.geometry} material={materials['Material.001']} position={[2.937, 0, -1.902]} rotation={[0.09, 0.87, -0.023]} scale={1.277} />
            <mesh geometry={nodes.Object_542.geometry} material={materials['Material.001']} position={[2.767, 0, -1.928]} rotation={[-3.058, 0.569, 2.827]} scale={1.302} />
            <mesh geometry={nodes.Object_544.geometry} material={materials['Material.001']} position={[2.82, 0, -1.923]} rotation={[-3.11, 0.745, 3.046]} scale={1.397} />
            <mesh geometry={nodes.Object_546.geometry} material={materials['Material.001']} position={[2.694, 0, -1.94]} rotation={[-3.091, 0.015, -3.096]} scale={1.535} />
            <mesh geometry={nodes.Object_548.geometry} material={materials['Material.001']} position={[2.711, 0, -1.762]} rotation={[0.121, -1.092, 0.112]} scale={0.999} />
            <mesh geometry={nodes.Object_550.geometry} material={materials['Material.001']} position={[2.92, 0, -1.823]} rotation={[-2.894, 0.257, 3.138]} scale={1.441} />
            <mesh geometry={nodes.Object_552.geometry} material={materials['Material.001']} position={[3.014, 0, -1.76]} rotation={[-2.907, 0.963, 2.954]} scale={1.33} />
            <mesh geometry={nodes.Object_554.geometry} material={materials['Material.001']} position={[2.992, 0, -1.582]} rotation={[-2.913, 0.013, -3.049]} scale={0.934} />
            <mesh geometry={nodes.Object_556.geometry} material={materials['Material.001']} position={[2.581, 0, -0.617]} rotation={[-0.873, 1.14, 0.905]} scale={1.946} />
            <mesh geometry={nodes.Object_558.geometry} material={materials['Material.001']} position={[2.764, 0, -1.655]} rotation={[-3.124, -0.367, -3.127]} scale={1.078} />
            <mesh geometry={nodes.Object_560.geometry} material={materials['Material.001']} position={[2.074, 0, -0.587]} rotation={[2.353, -1.115, 2.491]} scale={1.638} />
            <mesh geometry={nodes.Object_562.geometry} material={materials['Material.001']} position={[2.155, 0, -0.68]} rotation={[2.668, 1.178, -2.453]} scale={1.378} />
            <mesh geometry={nodes.Object_564.geometry} material={materials['Material.001']} position={[2.263, 0, -0.388]} rotation={[0.273, 1.328, -0.455]} scale={1.893} />
            <mesh geometry={nodes.Object_566.geometry} material={materials['Material.001']} position={[2.786, 0, -1.528]} rotation={[-0.168, -0.042, -0.027]} scale={1.058} />
            <mesh geometry={nodes.Object_568.geometry} material={materials['Material.001']} position={[2.067, 0, -0.457]} rotation={[0.074, -0.884, 0.187]} scale={1.477} />
            <mesh geometry={nodes.Object_570.geometry} material={materials['Material.001']} position={[2.837, 0, -1.414]} rotation={[0.015, -0.929, 0.004]} scale={1.856} />
            <mesh geometry={nodes.Object_572.geometry} material={materials['Material.001']} position={[1.748, 0, -0.581]} rotation={[-2.585, -1.373, -2.52]} scale={1.627} />
            <mesh geometry={nodes.Object_574.geometry} material={materials['Material.001']} position={[1.81, 0, -0.512]} rotation={[3.139, 0.586, 2.81]} scale={1.763} />
            <mesh geometry={nodes.Object_576.geometry} material={materials['Material.001']} position={[1.877, 0, -0.525]} rotation={[-3.056, 0.147, 3.14]} scale={1.43} />
            <mesh geometry={nodes.Object_578.geometry} material={materials['Material.001']} position={[1.674, 0, -0.497]} rotation={[-0.146, 0.266, -0.049]} scale={1.183} />
            <mesh geometry={nodes.Object_580.geometry} material={materials['Material.001']} position={[1.546, 0, -0.389]} rotation={[-3.11, -1.313, -2.776]} scale={1.686} />
            <mesh geometry={nodes.Object_582.geometry} material={materials['Material.001']} position={[2.997, 0, -1.453]} rotation={[0.015, 0.56, 0.002]} scale={1.794} />
            <mesh geometry={nodes.Object_584.geometry} material={materials['Material.001']} position={[1.466, 0, -0.66]} rotation={[0.067, 0.52, 0.121]} scale={1.275} />
            <mesh geometry={nodes.Object_586.geometry} material={materials['Material.001']} position={[2.946, 0, -1.052]} rotation={[3.102, -1.473, 3.118]} scale={1.043} />
            <mesh geometry={nodes.Object_588.geometry} material={materials['Material.001']} position={[2.852, 0, -0.975]} rotation={[-0.212, 0.26, -0.067]} scale={1.476} />
            <mesh geometry={nodes.Object_590.geometry} material={materials['Material.001']} position={[3.008, 0, -0.935]} rotation={[-3.064, -1.061, -2.854]} scale={1.688} />
            <mesh geometry={nodes.Object_592.geometry} material={materials['Material.001']} position={[2.799, 0, -0.949]} rotation={[2.663, 1.086, -2.441]} scale={1.295} />
            <mesh geometry={nodes.Object_594.geometry} material={materials['Material.001']} position={[2.839, 0, -0.843]} rotation={[-2.266, 1.328, 2.264]} scale={1.981} />
            <mesh geometry={nodes.Object_596.geometry} material={materials['Material.001']} position={[2.775, 0, -0.615]} rotation={[-3.134, -0.527, 3.052]} scale={1.507} />
            <mesh geometry={nodes.Object_598.geometry} material={materials['Material.001']} position={[-2.872, 0, -0.294]} rotation={[-2.869, -0.714, -2.823]} scale={1.178} />
            <mesh geometry={nodes.Object_600.geometry} material={materials['Material.001']} position={[2.922, 0, -0.715]} rotation={[0.088, -0.628, 0.351]} scale={0.966} />
            <mesh geometry={nodes.Object_602.geometry} material={materials['Material.001']} position={[-2.931, 0, -0.223]} rotation={[-0.012, -1.126, 0.181]} scale={1.443} />
            <mesh geometry={nodes.Object_604.geometry} material={materials['Material.001']} position={[2.77, 0, -0.501]} rotation={[3.116, 1.386, 3.051]} scale={1.335} />
            <mesh geometry={nodes.Object_606.geometry} material={materials['Material.001']} position={[2.844, 0, -0.559]} rotation={[-0.547, -1.193, -0.791]} scale={1.78} />
            <mesh geometry={nodes.Object_608.geometry} material={materials['Material.001']} position={[2.742, 0, -0.233]} rotation={[-0.46, -1.426, -0.319]} scale={0.836} />
            <mesh geometry={nodes.Object_610.geometry} material={materials['Material.001']} position={[2.872, 0, -0.28]} rotation={[3.053, -1.134, 3.079]} scale={1.26} />
            <mesh geometry={nodes.Object_612.geometry} material={materials['Material.001']} position={[2.919, 0, -0.244]} rotation={[2.173, 1.459, -2.399]} scale={1.75} />
            <mesh geometry={nodes.Object_614.geometry} material={materials['Material.001']} position={[2.795, 0, -0.044]} rotation={[3.14, 0.364, 2.995]} scale={1.041} />
            <mesh geometry={nodes.Object_616.geometry} material={materials['Material.001']} position={[2.812, 0, 0.034]} rotation={[-0.042, 0.295, -0.021]} scale={0.996} />
            <mesh geometry={nodes.Object_618.geometry} material={materials['Material.001']} position={[2.911, 0, 0.069]} rotation={[0.102, -0.89, 0.082]} scale={1.738} />
            <mesh geometry={nodes.Object_620.geometry} material={materials['Material.001']} position={[2.87, 0, 0.219]} rotation={[2.989, 0.811, -2.979]} scale={1.443} />
            <mesh geometry={nodes.Object_622.geometry} material={materials['Material.001']} position={[3.021, 0, 0.23]} rotation={[0.082, -0.427, 0.032]} scale={1.112} />
            <mesh geometry={nodes.Object_624.geometry} material={materials['Material.001']} position={[2.987, 0, 0.341]} rotation={[3.093, -1.34, -2.947]} scale={1.974} />
            <mesh geometry={nodes.Object_626.geometry} material={materials['Material.001']} position={[-2.817, 0, -0.157]} rotation={[3.111, -1.253, 3.117]} scale={1.734} />
            <mesh geometry={nodes.Object_628.geometry} material={materials['Material.001']} position={[2.858, 0, 0.431]} rotation={[0.164, 1.516, -0.16]} scale={1.765} />
            <mesh geometry={nodes.Object_630.geometry} material={materials['Material.001']} position={[2.727, 0, 0.773]} rotation={[0.081, -0.494, 0.371]} scale={1.893} />
            <mesh geometry={nodes.Object_632.geometry} material={materials['Material.001']} position={[2.739, 0, 0.827]} rotation={[-1.221, -1.352, -1.369]} scale={1.626} />
            <mesh geometry={nodes.Object_634.geometry} material={materials['Material.001']} position={[2.885, 0, 0.761]} rotation={[-0.018, -0.312, -0.008]} scale={1.724} />
            <mesh geometry={nodes.Object_636.geometry} material={materials['Material.001']} position={[2.922, 0, 1.035]} rotation={[-0.077, -1.117, 0.008]} scale={1.252} />
            <mesh geometry={nodes.Object_638.geometry} material={materials['Material.001']} position={[2.863, 0, 1.053]} rotation={[-3.094, 0.323, 3.128]} scale={1.719} />
            <mesh geometry={nodes.Object_640.geometry} material={materials['Material.001']} position={[2.922, 0, 1.09]} rotation={[0.054, -0.747, 0.128]} scale={1.796} />
            <mesh geometry={nodes.Object_642.geometry} material={materials['Material.001']} position={[2.691, 0, 1.092]} rotation={[-3.097, 0.12, -3.007]} scale={0.841} />
            <mesh geometry={nodes.Object_644.geometry} material={materials['Material.001']} position={[-1.079, 0, -0.486]} rotation={[0.106, -0.014, -0.054]} scale={1.05} />
            <mesh geometry={nodes.Object_646.geometry} material={materials['Material.001']} position={[-0.965, 0, -0.401]} rotation={[-3.031, 0.184, -2.939]} scale={1.555} />
            <mesh geometry={nodes.Object_648.geometry} material={materials['Material.001']} position={[-1.128, 0, -0.409]} rotation={[3.136, 0.089, 2.981]} scale={1.505} />
            <group position={[0,-0.5,0]}>
                <group ref={middleCatRef} >
                        <mesh geometry={nodes.Object_650.geometry} material={materials['Material.001']} position={[-0.081, 0, -2.276]} rotation={[0, 0.024, 0]} scale={0.717} />
                </group>
            </group>
            <mesh geometry={nodes.Object_652.geometry} material={materials['Material.001']} position={[-1.102, 0, 0.034]} rotation={[0.044, 0.306, -0.076]} scale={1.049} />
            <group position={[0,-0.3,0]}>
                <group ref={rightCatRef} >
                        <mesh geometry={nodes.Object_654.geometry} material={materials['Material.001']} position={[0.894, 0.009, 2.025]} rotation={[0.007, -0.294, 0.059]} scale={1.141} />
                </group>
            </group>
            <mesh geometry={nodes.Object_656.geometry} material={materials['Material.001']} position={[-1.27, 0, -0.524]} rotation={[0.109, 1.122, -0.061]} scale={1.292} />
            <mesh geometry={nodes.Object_658.geometry} material={materials['Material.001']} position={[-1.296, 0, -0.424]} rotation={[2.923, 0.239, -2.986]} scale={1.599} />
            <mesh geometry={nodes.Object_660.geometry} material={materials['Material.001']} position={[-1.402, 0, -0.386]} rotation={[-0.894, 1.477, 0.908]} scale={1.827} />
            <mesh geometry={nodes.Object_662.geometry} material={materials['Material.001']} position={[2.677, 0, 1.156]} rotation={[-2.958, -1.383, -2.627]} scale={1.068} />
            <mesh geometry={nodes.Object_664.geometry} material={materials['Material.001']} position={[-2.353, -0.009, -1.357]} rotation={[-Math.PI, 0.072, -Math.PI]} scale={[0.673, 0.82, 0.673]} />
            <mesh geometry={nodes.Object_666.geometry} material={materials['Material.001']} position={[0.393, 3.269, -1.094]} rotation={[0, -0.79, Math.PI]} scale={1.047} />
            <mesh geometry={nodes.Object_668.geometry} material={materials['Material.001']} position={[1.985, 2.797, -0.17]} rotation={[0, -1.512, 3.142]} scale={1.047} />
            <mesh geometry={nodes.Object_670.geometry} material={materials['Material.001']} position={[-1.441, 2.497, -2.247]} rotation={[-Math.PI, -0.751, 0]} scale={0.95} />
            <mesh geometry={nodes.Object_672.geometry} material={materials['Material.001']} position={[1.288, 2.637, -2.75]} rotation={[-Math.PI, -0.204, 0]} scale={1.132} />
            <mesh geometry={nodes.Object_674.geometry} material={materials['Material.001']} position={[-0.125, 3.091, -3.375]} rotation={[-Math.PI, -0.111, 0]} scale={1.242} />
            <mesh geometry={nodes.Object_676.geometry} material={materials['Material.001']} position={[-1.618, -0.009, -3.696]} rotation={[-Math.PI, -0.536, -Math.PI]} scale={[1.145, 1.302, 1.145]} />
            <mesh geometry={nodes.Object_678.geometry} material={materials['Material.001']} position={[-1.207, -0.009, -2.491]} rotation={[-Math.PI, -0.054, -Math.PI]} scale={[0.815, 0.927, 0.815]} />
            <mesh geometry={nodes.Object_680.geometry} material={materials['Material.001']} position={[-1.019, -0.009, -1.849]} rotation={[-Math.PI, -0.725, -Math.PI]} scale={0.612} />
            <mesh geometry={nodes.Object_682.geometry} material={materials['Material.001']} position={[-2.631, 0, -0.402]} rotation={[-3.119, 0.544, 2.93]} scale={1.478} />
            <mesh geometry={nodes.Object_684.geometry} material={materials['Material.001']} position={[2.354, 0, -0.379]} rotation={[-2.54, 1.069, 2.59]} scale={1.385} />
            <mesh geometry={nodes.Object_686.geometry} material={materials['Material.001']} position={[2.455, 0, -0.267]} rotation={[-1.557, 1.481, 1.53]} scale={1.884} />
            <mesh geometry={nodes.Object_688.geometry} material={materials['Material.001']} position={[2.63, 0, -0.304]} rotation={[0.312, 0.287, -0.236]} scale={1.508} />
            <mesh geometry={nodes.Object_690.geometry} material={materials['Material.001']} position={[2.616, 0, -0.204]} rotation={[0.116, 0.639, 0.263]} scale={0.982} />
            <mesh geometry={nodes.Object_692.geometry} material={materials['Material.001']} position={[2.313, 0, -0.068]} rotation={[0.004, 0.274, 0.046]} scale={1.953} />
            <mesh geometry={nodes.Object_694.geometry} material={materials['Material.001']} position={[2.294, 0, -0.012]} rotation={[0.034, 0.464, -0.116]} scale={0.931} />
            <mesh geometry={nodes.Object_696.geometry} material={materials['Material.001']} position={[2.014, 0, -0.26]} rotation={[0.017, 1.025, 0.014]} scale={0.906} />
            <mesh geometry={nodes.Object_698.geometry} material={materials['Material.001']} position={[2.098, 0, -0.299]} rotation={[0.217, -0.921, 0.094]} scale={1.584} />
            <mesh geometry={nodes.Object_700.geometry} material={materials['Material.001']} position={[2.146, 0, -0.043]} rotation={[-3.113, 0.631, -3.105]} scale={1.461} />
            <mesh geometry={nodes.Object_702.geometry} material={materials['Material.001']} position={[2.071, 0, -0.108]} rotation={[-3.106, 0.891, 3.04]} scale={1.49} />
            <mesh geometry={nodes.Object_704.geometry} material={materials['Material.001']} position={[1.527, 0, -0.314]} rotation={[3.076, -0.092, -3.094]} scale={1.927} />
            <mesh geometry={nodes.Object_706.geometry} material={materials['Material.001']} position={[1.815, 0, -0.366]} rotation={[-2.828, 0.904, 2.965]} scale={1.108} />
            <mesh geometry={nodes.Object_708.geometry} material={materials['Material.001']} position={[1.547, 0, -0.111]} rotation={[-0.299, -0.42, -0.279]} scale={1.303} />
            <mesh geometry={nodes.Object_710.geometry} material={materials['Material.001']} position={[2.771, 0, 1.368]} rotation={[0.411, 1.251, -0.121]} scale={0.861} />
            <mesh geometry={nodes.Object_712.geometry} material={materials['Material.001']} position={[1.403, 0, -0.377]} rotation={[0.052, 1.188, 0.027]} scale={1.793} />
            <mesh geometry={nodes.Object_714.geometry} material={materials['Material.001']} position={[1.442, 0, -0.223]} rotation={[3.016, 0.513, -2.955]} scale={1.759} />
            <mesh geometry={nodes.Object_716.geometry} material={materials['Material.001']} position={[1.482, 0, -0.013]} rotation={[-3.136, -0.182, -3.103]} scale={1.43} />
            <mesh geometry={nodes.Object_718.geometry} material={materials['Material.001']} position={[1.299, 0, -0.104]} rotation={[-2.676, -1.246, -2.799]} scale={1.312} />
            <mesh geometry={nodes.Object_720.geometry} material={materials['Material.001']} position={[2.863, 0, 1.608]} rotation={[-0.216, 1.455, 0.227]} scale={1.077} />
            <mesh geometry={nodes.Object_722.geometry} material={materials['Material.001']} position={[-2.677, 0, 2.631]} rotation={[-2.996, 0.815, 3.029]} scale={1.486} />
            <mesh geometry={nodes.Object_724.geometry} material={materials['Material.001']} position={[2.924, 0, 1.652]} rotation={[2.875, 0.133, -3.032]} scale={1.014} />
            <mesh geometry={nodes.Object_726.geometry} material={materials['Material.001']} position={[2.895, 0, 1.776]} rotation={[2.932, 0.605, -2.931]} scale={0.878} />
            <mesh geometry={nodes.Object_728.geometry} material={materials['Material.001']} position={[2.785, 0, 1.721]} rotation={[0.131, 0.478, 0.09]} scale={1.838} />
            <mesh geometry={nodes.Object_730.geometry} material={materials['Material.001']} position={[-2.674, 0, -0.15]} rotation={[0.19, 0.548, 0.125]} scale={1.488} />
            <mesh geometry={nodes.Object_732.geometry} material={materials['Material.001']} position={[2.752, 0, 1.862]} rotation={[-0.882, 1.422, 0.84]} scale={1.07} />
            <mesh geometry={nodes.Object_734.geometry} material={materials['Material.001']} position={[2.921, 0, 2.001]} rotation={[-3.112, 0.508, 2.984]} scale={1.768} />
            <mesh geometry={nodes.Object_736.geometry} material={materials['Material.001']} position={[3, 0, 2.258]} rotation={[-0.373, -1.381, -0.186]} scale={1.021} />
            <mesh geometry={nodes.Object_738.geometry} material={materials['Material.001']} position={[2.707, 0, 2.115]} rotation={[-3.019, 0.07, -3.125]} scale={1.991} />
            <mesh geometry={nodes.Object_740.geometry} material={materials['Material.001']} position={[2.775, 0, 2.131]} rotation={[0.073, -0.506, -0.024]} scale={1.578} />
            <mesh geometry={nodes.Object_742.geometry} material={materials['Material.001']} position={[2.925, 0, 2.287]} rotation={[-0.966, 1.548, 0.657]} scale={1.198} />
            <mesh geometry={nodes.Object_744.geometry} material={materials['Material.001']} position={[2.931, 0, 2.645]} rotation={[-0.219, -0.149, -0.209]} scale={0.833} />
            <mesh geometry={nodes.Object_746.geometry} material={materials['Material.001']} position={[2.718, 0, 2.516]} rotation={[-3.096, 0.874, 3.01]} scale={1.602} />
            <mesh geometry={nodes.Object_748.geometry} material={materials['Material.001']} position={[2.789, 0, 2.519]} rotation={[3.141, 0.294, 2.902]} scale={0.891} />
            <mesh geometry={nodes.Object_750.geometry} material={materials['Material.001']} position={[2.675, 0, 2.746]} rotation={[-2.698, 0.88, 2.804]} scale={1.079} />
            <mesh geometry={nodes.Object_752.geometry} material={materials['Material.001']} position={[2.959, 0, 2.723]} rotation={[-0.16, -1.184, 0.127]} scale={1.914} />
            <mesh geometry={nodes.Object_754.geometry} material={materials['Material.001']} position={[2.946, 0, 2.794]} rotation={[-0.319, 0.052, -0.074]} scale={1.117} />
            <mesh geometry={nodes.Object_756.geometry} material={materials['Material.001']} position={[2.716, 0, 2.989]} rotation={[3.017, 0.85, -2.957]} scale={1.97} />
            <mesh geometry={nodes.Object_758.geometry} material={materials['Material.001']} position={[2.33, 0, 2.736]} rotation={[1.27, 1.319, -1.032]} scale={1.521} />
            <mesh geometry={nodes.Object_760.geometry} material={materials['Material.001']} position={[2.382, 0, 2.829]} rotation={[-0.082, 1.139, 0.085]} scale={0.983} />
            <mesh geometry={nodes.Object_762.geometry} material={materials['Material.001']} position={[-0.974, 0, -0.263]} rotation={[0.184, -1.324, 0.524]} scale={1.08} />
            <mesh geometry={nodes.Object_764.geometry} material={materials['Material.001']} position={[2.307, 0, 2.809]} rotation={[-3.092, -0.044, 2.832]} scale={1.499} />
            <mesh geometry={nodes.Object_766.geometry} material={materials['Material.001']} position={[-0.896, 0, -0.18]} rotation={[3.134, -0.125, -3.135]} scale={1.317} />
            <mesh geometry={nodes.Object_768.geometry} material={materials['Material.001']} position={[2.587, 0, 2.667]} rotation={[0.101, -0.049, 0.319]} scale={1.915} />
            <mesh geometry={nodes.Object_770.geometry} material={materials['Material.001']} position={[-0.804, 0, -0.09]} rotation={[-0.003, -0.33, 0.182]} scale={1.87} />
            <mesh geometry={nodes.Object_772.geometry} material={materials['Material.001']} position={[-1.042, 0, -0.174]} rotation={[2.6, -0.921, 2.788]} scale={1.554} />
            <mesh geometry={nodes.Object_774.geometry} material={materials['Material.001']} position={[-0.966, 0, -0.024]} rotation={[2.872, -0.769, 2.977]} scale={1.508} />
            <mesh geometry={nodes.Object_776.geometry} material={materials['Material.001']} position={[-1.099, 0, -0.087]} rotation={[-3.096, -0.291, 2.935]} scale={1.709} />
            <mesh geometry={nodes.Object_778.geometry} material={materials['Material.001']} position={[-1.064, 0, -0.002]} rotation={[0.301, 1.305, -0.571]} scale={1.457} />
            <mesh geometry={nodes.Object_780.geometry} material={materials['Material.001']} position={[-1.334, 0, -0.271]} rotation={[-1.004, -1.478, -1.124]} scale={0.939} />
            <mesh geometry={nodes.Object_782.geometry} material={materials['Material.001']} position={[-1.272, 0, -0.122]} rotation={[-3.039, 1.253, 2.8]} scale={1.189} />
            <mesh geometry={nodes.Object_784.geometry} material={materials['Material.001']} position={[-1.277, 0, -0.057]} rotation={[0.14, 0.789, 0.047]} scale={1.112} />
            <mesh geometry={nodes.Object_786.geometry} material={materials['Material.001']} position={[-1.517, 0, -0.186]} rotation={[3.123, 1.155, -3.109]} scale={1.06} />
            <mesh geometry={nodes.Object_788.geometry} material={materials['Material.001']} position={[-1.438, 0, -0.041]} rotation={[-0.029, -0.693, 0.112]} scale={0.852} />
            <mesh geometry={nodes.Object_790.geometry} material={materials['Material.001']} position={[-1.563, 0, -0.303]} rotation={[2.705, 1.429, -2.848]} scale={1.626} />
            <mesh geometry={nodes.Object_792.geometry} material={materials['Material.001']} position={[-1.6, 0, -0.051]} rotation={[0.085, 0.647, 0.026]} scale={1.291} />
            <mesh geometry={nodes.Object_794.geometry} material={materials['Material.001']} position={[-1.716, 0, -0.135]} rotation={[-3.058, 0.584, 3.125]} scale={1.83} />
            <mesh geometry={nodes.Object_796.geometry} material={materials['Material.001']} position={[-2.274, 0, -0.205]} rotation={[2.525, 1.162, -2.437]} scale={1.496} />
            <mesh geometry={nodes.Object_798.geometry} material={materials['Material.001']} position={[-1.9, 0, -0.041]} rotation={[-0.94, -1.421, -1.158]} scale={1.366} />
            <mesh geometry={nodes.Object_800.geometry} material={materials['Material.001']} position={[-2.133, 0, -0.149]} rotation={[0.008, -0.098, 0]} scale={1.78} />
            <mesh geometry={nodes.Object_802.geometry} material={materials['Material.001']} position={[2.58, 0, 2.829]} rotation={[-3.072, 0.357, -3.033]} scale={1.892} />
            <mesh geometry={nodes.Object_804.geometry} material={materials['Material.001']} position={[-2.377, 0, -0.237]} rotation={[-0.068, 0.589, 0.008]} scale={1.646} />
            <mesh geometry={nodes.Object_806.geometry} material={materials['Material.001']} position={[-2.629, 0, -0.105]} rotation={[0.376, -0.969, 0.199]} scale={1.334} />
            <mesh geometry={nodes.Object_808.geometry} material={materials['Material.001']} position={[2.503, 0, 0.311]} rotation={[0.951, -1.414, 0.951]} scale={1.19} />
            <mesh geometry={nodes.Object_810.geometry} material={materials['Material.001']} position={[2.488, 0, 2.771]} rotation={[-3.107, -0.758, -3.054]} scale={1.762} />
            <mesh geometry={nodes.Object_812.geometry} material={materials['Material.001']} position={[2.309, 0, 0.359]} rotation={[2.881, 0.522, -2.852]} scale={1.02} />
            <mesh geometry={nodes.Object_814.geometry} material={materials['Material.001']} position={[2.254, 0, 0.199]} rotation={[-2.923, 0.712, 3.099]} scale={0.876} />
            <mesh geometry={nodes.Object_816.geometry} material={materials['Material.001']} position={[2.26, 0, 0.379]} rotation={[-1.903, 1.379, 1.871]} scale={1.757} />
            <mesh geometry={nodes.Object_818.geometry} material={materials['Material.001']} position={[2.167, 0, 0.338]} rotation={[-0.217, -0.876, -0.248]} scale={1.247} />
            <mesh geometry={nodes.Object_820.geometry} material={materials['Material.001']} position={[2.491, 0, 2.872]} rotation={[0.027, 0.54, -0.134]} scale={1.439} />
            <mesh geometry={nodes.Object_822.geometry} material={materials['Material.001']} position={[1.533, 0, 0.134]} rotation={[-3.126, 0.416, 3.114]} scale={1.797} />
            <mesh geometry={nodes.Object_824.geometry} material={materials['Material.001']} position={[1.547, 0, 0.182]} rotation={[-0.143, 0.679, 0.104]} scale={1.582} />
            <mesh geometry={nodes.Object_826.geometry} material={materials['Material.001']} position={[1.712, 0, 0.078]} rotation={[3.112, 1.224, -3.122]} scale={1.424} />
            <mesh geometry={nodes.Object_828.geometry} material={materials['Material.001']} position={[1.863, 0, 0.031]} rotation={[0.277, 0.853, 0.018]} scale={1.34} />
            <mesh geometry={nodes.Object_830.geometry} material={materials['Material.001']} position={[2.654, 0, 2.994]} rotation={[-0.073, -0.632, -0.095]} scale={1.744} />
            <mesh geometry={nodes.Object_832.geometry} material={materials['Material.001']} position={[1.248, 0, 0.049]} rotation={[0.302, -0.928, 0.248]} scale={1.263} />
            <mesh geometry={nodes.Object_834.geometry} material={materials['Material.001']} position={[1.287, 0, 0.181]} rotation={[2.856, 1.309, -3.091]} scale={1.148} />
            <mesh geometry={nodes.Object_836.geometry} material={materials['Material.001']} position={[-2.779, 0, -0.076]} rotation={[-0.067, -0.374, -0.089]} scale={1.984} />
            <mesh geometry={nodes.Object_838.geometry} material={materials['Material.001']} position={[1.404, 0, 0.245]} rotation={[-3.1, 1.305, 2.817]} scale={1.388} />
            <mesh geometry={nodes.Object_840.geometry} material={materials['Material.001']} position={[2.349, 0, 2.966]} rotation={[-3.026, -0.343, 3.037]} scale={0.833} />
            <mesh geometry={nodes.Object_842.geometry} material={materials['Material.001']} position={[2.054, 0, 2.665]} rotation={[0.163, 0.496, 0.116]} scale={1.393} />
            <mesh geometry={nodes.Object_844.geometry} material={materials['Material.001']} position={[2.073, 0, 2.761]} rotation={[-0.161, -1.042, -0.18]} scale={1.998} />
            <mesh geometry={nodes.Object_846.geometry} material={materials['Material.001']} position={[2.17, 0, 2.749]} rotation={[2.479, -1.185, 2.531]} scale={1.59} />
            <mesh geometry={nodes.Object_848.geometry} material={materials['Material.001']} position={[1.945, 0, 2.976]} rotation={[-3.013, 0.765, 3.051]} scale={0.976} />
            <mesh geometry={nodes.Object_850.geometry} material={materials['Material.001']} position={[1.708, 0, 2.728]} rotation={[2.964, 1.405, -3.101]} scale={0.966} />
            <mesh geometry={nodes.Object_852.geometry} material={materials['Material.001']} position={[1.055, 0, 0.348]} rotation={[2.892, 0.149, -3.052]} scale={0.918} />
            <mesh geometry={nodes.Object_854.geometry} material={materials['Material.001']} position={[1.526, 0, 2.804]} rotation={[2.766, 1.014, -2.689]} scale={0.853} />
            <mesh geometry={nodes.Object_856.geometry} material={materials['Material.001']} position={[1.723, 0, 2.78]} rotation={[-2.97, -0.063, -3.087]} scale={1.781} />
            <mesh geometry={nodes.Object_858.geometry} material={materials['Material.001']} position={[1.767, 0, 2.826]} rotation={[-0.217, 0.359, -0.008]} scale={0.889} />
            <mesh geometry={nodes.Object_860.geometry} material={materials['Material.001']} position={[1.809, 0, 3.002]} rotation={[-0.003, 1.214, -0.016]} scale={1.241} />
            <mesh geometry={nodes.Object_862.geometry} material={materials['Material.001']} position={[1.886, 0, 2.975]} rotation={[2.997, -0.095, -3.135]} scale={0.844} />
            <mesh geometry={nodes.Object_864.geometry} material={materials['Material.001']} position={[1.577, 0, 2.881]} rotation={[0.075, -0.913, 0.355]} scale={1.757} />
            <mesh geometry={nodes.Object_866.geometry} material={materials['Material.001']} position={[-3.025, 0, -0.141]} rotation={[3.106, 0.329, -3.123]} scale={1.252} />
            <mesh geometry={nodes.Object_868.geometry} material={materials['Material.001']} position={[1.343, 0, 2.717]} rotation={[-3.105, -1.049, -2.82]} scale={1.882} />
            <mesh geometry={nodes.Object_870.geometry} material={materials['Material.001']} position={[1.476, 0, 2.938]} rotation={[0.147, 0.519, 0.135]} scale={1.988} />
            <mesh geometry={nodes.Object_872.geometry} material={materials['Material.001']} position={[1.353, 0, 3.036]} rotation={[-0.39, 0.794, 0.26]} scale={1.001} />
            <mesh geometry={nodes.Object_874.geometry} material={materials['Material.001']} position={[1.236, 0, 2.916]} rotation={[-0.12, 0.523, 0.076]} scale={1.972} />
            <mesh geometry={nodes.Object_876.geometry} material={materials['Material.001']} position={[-2.663, 0, -0.525]} rotation={[3.141, -0.222, 3.135]} scale={1.433} />
            <mesh geometry={nodes.Object_878.geometry} material={materials['Material.001']} position={[0.867, 0, 2.704]} rotation={[-3.085, 0.253, 2.832]} scale={1.641} />
            <mesh geometry={nodes.Object_880.geometry} material={materials['Material.001']} position={[-2.777, 0, -0.459]} rotation={[-2.862, -0.036, -3.031]} scale={1.552} />
            <mesh geometry={nodes.Object_882.geometry} material={materials['Material.001']} position={[-0.667, 0, 0.329]} rotation={[0.283, 0.962, -0.33]} scale={1.505} />
            <mesh geometry={nodes.Object_884.geometry} material={materials['Material.001']} position={[-0.982, 0, 0.048]} rotation={[3.141, -0.764, -3.106]} scale={1.159} />
            <mesh geometry={nodes.Object_886.geometry} material={materials['Material.001']} position={[-1.045, 0, 0.096]} rotation={[-0.402, -0.623, -0.372]} scale={1.742} />
            <mesh geometry={nodes.Object_888.geometry} material={materials['Material.001']} position={[-0.778, 0, 0.101]} rotation={[-3.119, -0.892, -2.985]} scale={0.938} />
            <mesh geometry={nodes.Object_890.geometry} material={materials['Material.001']} position={[-0.778, 0, 0.218]} rotation={[2.918, 0.448, -2.858]} scale={1.165} />
            <mesh geometry={nodes.Object_892.geometry} material={materials['Material.001']} position={[-1.004, 0, 0.26]} rotation={[2.87, 1.341, -3.021]} scale={1.772} />
            <mesh geometry={nodes.Object_894.geometry} material={materials['Material.001']} position={[-1.102, 0, 0.291]} rotation={[0.02, 0.019, 0.137]} scale={0.843} />
            <mesh geometry={nodes.Object_896.geometry} material={materials['Material.001']} position={[-1.475, 0, 0.037]} rotation={[0.058, -0.246, -0.123]} scale={1.634} />
            <mesh geometry={nodes.Object_898.geometry} material={materials['Material.001']} position={[-1.431, 0, 0.109]} rotation={[2.847, -0.755, 2.906]} scale={1.396} />
            <mesh geometry={nodes.Object_900.geometry} material={materials['Material.001']} position={[-1.236, 0, 0.088]} rotation={[-0.162, -1.225, -0.244]} scale={1.874} />
            <mesh geometry={nodes.Object_902.geometry} material={materials['Material.001']} position={[-1.346, 0, 0.295]} rotation={[0.122, -0.05, 0.205]} scale={0.95} />
            <mesh geometry={nodes.Object_904.geometry} material={materials['Material.001']} position={[-1.593, 0, 0.029]} rotation={[2.226, 1.475, -2.112]} scale={1.44} />
            <mesh geometry={nodes.Object_906.geometry} material={materials['Material.001']} position={[-1.829, 0, 0.21]} rotation={[-0.176, 0.972, 0.156]} scale={1.997} />
            <mesh geometry={nodes.Object_908.geometry} material={materials['Material.001']} position={[-2.227, 0, 0.001]} rotation={[3.058, 0.198, -3.119]} scale={1.405} />
            <mesh geometry={nodes.Object_910.geometry} material={materials['Material.001']} position={[-2.192, 0, 0.138]} rotation={[2.743, 1.525, -2.78]} scale={1.49} />
            <mesh geometry={nodes.Object_912.geometry} material={materials['Material.001']} position={[0.778, 0, 2.847]} rotation={[-2.773, 0.531, 3.009]} scale={1.991} />
            <mesh geometry={nodes.Object_914.geometry} material={materials['Material.001']} position={[-1.946, 0, 0.084]} rotation={[-2.795, 0.361, 3.13]} scale={1.028} />
            <mesh geometry={nodes.Object_916.geometry} material={materials['Material.001']} position={[-2.059, 0, 0.141]} rotation={[2.885, -0.282, -3.131]} scale={1.163} />
            <mesh geometry={nodes.Object_918.geometry} material={materials['Material.001']} position={[1.068, 0, 2.675]} rotation={[-0.211, 1.073, 0.217]} scale={1.677} />
            <mesh geometry={nodes.Object_920.geometry} material={materials['Material.001']} position={[0.924, 0, 3.015]} rotation={[-0.651, 1.04, 0.617]} scale={1.279} />
            <mesh geometry={nodes.Object_922.geometry} material={materials['Material.001']} position={[-1.919, 0, 0.327]} rotation={[2.958, -0.316, 3.083]} scale={1.674} />
            <mesh geometry={nodes.Object_924.geometry} material={materials['Material.001']} position={[-2.003, 0, 0.285]} rotation={[0.009, 0.184, -0.051]} scale={1.822} />
            <mesh geometry={nodes.Object_926.geometry} material={materials['Material.001']} position={[-2.207, 0, 0.218]} rotation={[-3.087, -0.779, -2.92]} scale={1.323} />
            <mesh geometry={nodes.Object_928.geometry} material={materials['Material.001']} position={[-2.185, 0, 0.326]} rotation={[3.042, 0.737, -2.99]} scale={1.546} />
            <mesh geometry={nodes.Object_930.geometry} material={materials['Material.001']} position={[0.55, 0, 2.721]} rotation={[0.266, 1.479, -0.282]} scale={1.845} />
            <mesh geometry={nodes.Object_932.geometry} material={materials['Material.001']} position={[-2.317, 0, 0.352]} rotation={[2.841, 0.947, -2.706]} scale={1.918} />
            <mesh geometry={nodes.Object_934.geometry} material={materials['Material.001']} position={[2.655, 0, 0.41]} rotation={[-3.103, -0.628, 3.138]} scale={1.958} />
            <mesh geometry={nodes.Object_936.geometry} material={materials['Material.001']} position={[2.491, 0, 0.497]} rotation={[-0.011, -0.951, 0.176]} scale={1.479} />
            <mesh geometry={nodes.Object_938.geometry} material={materials['Material.001']} position={[2.49, 0, 0.637]} rotation={[-2.914, -0.964, -3.102]} scale={1.544} />
            <mesh geometry={nodes.Object_940.geometry} material={materials['Material.001']} position={[2.558, 0, 0.582]} rotation={[0.126, -0.639, -0.007]} scale={1.823} />
            <mesh geometry={nodes.Object_942.geometry} material={materials['Material.001']} position={[2.29, 0, 0.694]} rotation={[0.161, 1.275, -0.035]} scale={1.148} />
            <mesh geometry={nodes.Object_944.geometry} material={materials['Material.001']} position={[2.185, 0, 0.456]} rotation={[3.044, 0.414, -3.04]} scale={1.276} />
            <mesh geometry={nodes.Object_946.geometry} material={materials['Material.001']} position={[2.238, 0, 0.481]} rotation={[2.936, 1.311, 3.039]} scale={1.202} />
            <mesh geometry={nodes.Object_948.geometry} material={materials['Material.001']} position={[2.215, 0, 0.594]} rotation={[0.307, -0.914, 0.195]} scale={1.833} />
            <mesh geometry={nodes.Object_950.geometry} material={materials['Material.001']} position={[1.977, 0, 0.598]} rotation={[-2.926, -1.156, -2.893]} scale={1.679} />
            <mesh geometry={nodes.Object_952.geometry} material={materials['Material.001']} position={[1.979, 0, 0.74]} rotation={[0.066, -0.765, 0.192]} scale={1.731} />
            <mesh geometry={nodes.Object_954.geometry} material={materials['Material.001']} position={[1.887, 0, 0.385]} rotation={[-2.986, 0.692, 3.132]} scale={1.553} />
            <mesh geometry={nodes.Object_956.geometry} material={materials['Material.001']} position={[1.214, 0, 0.434]} rotation={[-0.05, 0.383, -0.006]} scale={0.864} />
            <mesh geometry={nodes.Object_958.geometry} material={materials['Material.001']} position={[1.16, 0, 0.475]} rotation={[0.081, 0.764, -0.282]} scale={1.943} />
            <mesh geometry={nodes.Object_960.geometry} material={materials['Material.001']} position={[1.402, 0, 0.485]} rotation={[-0.357, 1.063, 0.297]} scale={1.287} />
            <mesh geometry={nodes.Object_962.geometry} material={materials['Material.001']} position={[1.405, 0, 0.606]} rotation={[0.114, 0.044, -0.105]} scale={1.297} />
            <mesh geometry={nodes.Object_964.geometry} material={materials['Material.001']} position={[1.363, 0, 0.742]} rotation={[0.024, 1.195, 0.012]} scale={1.831} />
            <mesh geometry={nodes.Object_966.geometry} material={materials['Material.001']} position={[1.228, 0, 0.734]} rotation={[2.271, -1.319, 2.324]} scale={1.553} />
            <mesh geometry={nodes.Object_968.geometry} material={materials['Material.001']} position={[1.131, 0, 0.565]} rotation={[-3.006, -0.603, 2.946]} scale={1.715} />
            <mesh geometry={nodes.Object_970.geometry} material={materials['Material.001']} position={[0.515, 0, 2.84]} rotation={[-0.226, 0.394, 0.092]} scale={1.223} />
            <mesh geometry={nodes.Object_972.geometry} material={materials['Material.001']} position={[0.981, 0, 0.741]} rotation={[-0.128, 1.157, 0.095]} scale={1.335} />
            <mesh geometry={nodes.Object_974.geometry} material={materials['Material.001']} position={[0.88, 0, 0.752]} rotation={[0.027, 0.829, 0.019]} scale={0.846} />
            <mesh geometry={nodes.Object_976.geometry} material={materials['Material.001']} position={[0.726, 0, 2.709]} rotation={[-0.741, 1.074, 0.71]} scale={1.91} />
            <mesh geometry={nodes.Object_978.geometry} material={materials['Material.001']} position={[0.688, 0, 2.831]} rotation={[-2.994, -0.536, 2.916]} scale={1.097} />
            <mesh geometry={nodes.Object_980.geometry} material={materials['Material.001']} position={[0.56, 0, 2.967]} rotation={[-3.118, -0.93, -3.132]} scale={1.807} />
            <mesh geometry={nodes.Object_982.geometry} material={materials['Material.001']} position={[0.569, 0, 0.682]} rotation={[-2.934, -0.42, -2.922]} scale={1.728} />
            <mesh geometry={nodes.Object_984.geometry} material={materials['Material.001']} position={[0.061, 0, 2.658]} rotation={[0.029, 0.126, 0.165]} scale={1.975} />
            <mesh geometry={nodes.Object_986.geometry} material={materials['Material.001']} position={[0.215, 0, 2.715]} rotation={[2.9, -0.434, 3.083]} scale={1.547} />
            <mesh geometry={nodes.Object_988.geometry} material={materials['Material.001']} position={[0.354, 0, 2.986]} rotation={[2.875, 1.39, -2.86]} scale={1.162} />
            <mesh geometry={nodes.Object_990.geometry} material={materials['Material.001']} position={[0.116, 0, 2.877]} rotation={[2.917, -0.484, 3.023]} scale={1.102} />
            <mesh geometry={nodes.Object_992.geometry} material={materials['Material.001']} position={[0.113, 0, 2.962]} rotation={[-0.49, 1.341, 0.511]} scale={1.14} />
            <mesh geometry={nodes.Object_994.geometry} material={materials['Material.001']} position={[-0.23, 0, 2.697]} rotation={[0.109, -0.651, -0.004]} scale={1.796} />
            <mesh geometry={nodes.Object_996.geometry} material={materials['Material.001']} position={[-0.171, 0, 2.842]} rotation={[-2.832, 0.381, 3.077]} scale={1.066} />
            <mesh geometry={nodes.Object_998.geometry} material={materials['Material.001']} position={[-0.274, 0, 3.034]} rotation={[-3.099, -0.033, 2.84]} scale={1.44} />
            <mesh geometry={nodes.Object_1000.geometry} material={materials['Material.001']} position={[-2.941, 0, -0.952]} rotation={[-1.49, 1.256, 1.498]} scale={1.911} />
            <mesh geometry={nodes.Object_1002.geometry} material={materials['Material.001']} position={[-0.661, 0, 2.715]} rotation={[-0.063, 0.768, 0.031]} scale={1.344} />
            <mesh geometry={nodes.Object_1004.geometry} material={materials['Material.001']} position={[-0.392, 0, 2.813]} rotation={[0.188, 1.24, -0.22]} scale={1.56} />
            <mesh geometry={nodes.Object_1006.geometry} material={materials['Material.001']} position={[-0.458, 0, 3.027]} rotation={[-0.116, -0.841, -0.173]} scale={1.728} />
            <mesh geometry={nodes.Object_1008.geometry} material={materials['Material.001']} position={[-0.699, 0, 2.881]} rotation={[-3.093, -1.092, -3.088]} scale={1.16} />
            <mesh geometry={nodes.Object_1010.geometry} material={materials['Material.001']} position={[-0.563, 0, 0.461]} rotation={[0.125, 0.067, 0.236]} scale={1.364} />
            <mesh geometry={nodes.Object_1012.geometry} material={materials['Material.001']} position={[-0.404, 0, 0.695]} rotation={[0.275, 1.029, 0.053]} scale={0.922} />
            <mesh geometry={nodes.Object_1014.geometry} material={materials['Material.001']} position={[-0.868, 0, 2.741]} rotation={[2.882, -1.094, 2.96]} scale={1.269} />
            <mesh geometry={nodes.Object_1016.geometry} material={materials['Material.001']} position={[-0.894, 0, 0.416]} rotation={[-0.219, -1.383, 0.079]} scale={1.963} />
            <mesh geometry={nodes.Object_1018.geometry} material={materials['Material.001']} position={[-0.825, 0, 0.432]} rotation={[-0.025, -1.415, -0.034]} scale={1.539} />
            <mesh geometry={nodes.Object_1020.geometry} material={materials['Material.001']} position={[-0.813, 0, 0.72]} rotation={[-0.411, -0.801, -0.475]} scale={1.515} />
            <mesh geometry={nodes.Object_1022.geometry} material={materials['Material.001']} position={[-1.102, 0, 0.593]} rotation={[-1.415, 1.481, 1.403]} scale={1.051} />
            <mesh geometry={nodes.Object_1024.geometry} material={materials['Material.001']} position={[-1.006, 0, 0.586]} rotation={[-0.039, -0.954, -0.072]} scale={0.859} />
            <mesh geometry={nodes.Object_1026.geometry} material={materials['Material.001']} position={[-1.338, 0, 0.386]} rotation={[0.402, 1.083, -0.111]} scale={1.599} />
            <mesh geometry={nodes.Object_1028.geometry} material={materials['Material.001']} position={[-1.254, 0, 0.551]} rotation={[2.243, -1.232, 2.338]} scale={1.672} />
            <mesh geometry={nodes.Object_1030.geometry} material={materials['Material.001']} position={[-1.281, 0, 0.616]} rotation={[3.141, -0.749, 3.141]} scale={1.911} />
            <mesh geometry={nodes.Object_1032.geometry} material={materials['Material.001']} position={[-1.369, 0, 0.575]} rotation={[0.064, -0.533, 0.357]} scale={0.893} />
            <mesh geometry={nodes.Object_1034.geometry} material={materials['Material.001']} position={[-1.482, 0, 0.724]} rotation={[0.326, 1.271, -0.169]} scale={1.657} />
            <mesh geometry={nodes.Object_1036.geometry} material={materials['Material.001']} position={[-1.8, 0, 0.505]} rotation={[0.097, -0.167, 0.3]} scale={1.367} />
            <mesh geometry={nodes.Object_1038.geometry} material={materials['Material.001']} position={[-0.773, 0, 2.675]} rotation={[0.078, 1.395, -0.354]} scale={1.453} />
            <mesh geometry={nodes.Object_1040.geometry} material={materials['Material.001']} position={[-1.609, 0, 0.44]} rotation={[-1.904, -1.327, -2.117]} scale={1.947} />
            <mesh geometry={nodes.Object_1042.geometry} material={materials['Material.001']} position={[-1.555, 0, 0.465]} rotation={[0.003, 0.262, 0.002]} scale={1.316} />
            <mesh geometry={nodes.Object_1044.geometry} material={materials['Material.001']} position={[-1.666, 0, 0.495]} rotation={[-2.589, -1.378, -2.675]} scale={1.469} />
            <mesh geometry={nodes.Object_1046.geometry} material={materials['Material.001']} position={[-1.579, 0, 0.608]} rotation={[-0.305, -1.394, -0.182]} scale={0.931} />
            <mesh geometry={nodes.Object_1048.geometry} material={materials['Material.001']} position={[-1.671, 0, 0.737]} rotation={[3.017, 1.126, -2.95]} scale={1.19} />
            <mesh geometry={nodes.Object_1050.geometry} material={materials['Material.001']} position={[-0.934, 0, 2.975]} rotation={[2.907, 0.263, -3.007]} scale={1.959} />
            <mesh geometry={nodes.Object_1052.geometry} material={materials['Material.001']} position={[-2.251, 0, 0.475]} rotation={[0.095, -0.11, 0.17]} scale={1.625} />
            <mesh geometry={nodes.Object_1054.geometry} material={materials['Material.001']} position={[-2.243, 0, 0.548]} rotation={[0.283, 0.126, -0.229]} scale={1.773} />
            <mesh geometry={nodes.Object_1056.geometry} material={materials['Material.001']} position={[-1.964, 0, 0.631]} rotation={[0.046, 0.316, -0.191]} scale={1.398} />
            <mesh geometry={nodes.Object_1058.geometry} material={materials['Material.001']} position={[-1.917, 0, 0.739]} rotation={[0.048, 0.129, 0.264]} scale={1.373} />
            <mesh geometry={nodes.Object_1060.geometry} material={materials['Material.001']} position={[-2.205, 0, 0.629]} rotation={[0.178, 0.87, -0.381]} scale={0.979} />
            <mesh geometry={nodes.Object_1062.geometry} material={materials['Material.001']} position={[-2.654, 0, 0.41]} rotation={[-0.048, -0.496, -0.037]} scale={1.449} />
            <mesh geometry={nodes.Object_1064.geometry} material={materials['Material.001']} position={[-2.632, 0, 0.506]} rotation={[3.061, 0.039, -3.078]} scale={0.91} />
            <mesh geometry={nodes.Object_1066.geometry} material={materials['Material.001']} position={[-2.585, 0, 0.533]} rotation={[-3.129, 0.239, 3.139]} scale={0.951} />
            <mesh geometry={nodes.Object_1068.geometry} material={materials['Material.001']} position={[-2.452, 0, 0.399]} rotation={[3.008, -0.43, -3.116]} scale={0.889} />
            <mesh geometry={nodes.Object_1070.geometry} material={materials['Material.001']} position={[-2.381, 0, 0.477]} rotation={[-3, 0.332, -3.089]} scale={1.734} />
            <mesh geometry={nodes.Object_1072.geometry} material={materials['Material.001']} position={[-2.402, 0, 0.558]} rotation={[0.163, 0.05, -0.047]} scale={1.682} />
            <mesh geometry={nodes.Object_1074.geometry} material={materials['Material.001']} position={[-2.378, 0, 0.618]} rotation={[0.001, 0.537, -0.021]} scale={1.262} />
            <mesh geometry={nodes.Object_1076.geometry} material={materials['Material.001']} position={[2.45, 0, 0.997]} rotation={[0.101, 1.301, -0.02]} scale={1.31} />
            <mesh geometry={nodes.Object_1078.geometry} material={materials['Material.001']} position={[2.059, 0, 0.826]} rotation={[-0.297, 0.314, -0.026]} scale={1.274} />
            <mesh geometry={nodes.Object_1080.geometry} material={materials['Material.001']} position={[2.19, 0, 0.774]} rotation={[-2.981, 0.642, 3.121]} scale={1.727} />
            <mesh geometry={nodes.Object_1082.geometry} material={materials['Material.001']} position={[-1.057, 0, 2.926]} rotation={[0.129, 1.018, -0.163]} scale={0.937} />
            <mesh geometry={nodes.Object_1084.geometry} material={materials['Material.001']} position={[2.167, 0, 0.995]} rotation={[-2.954, -0.748, 3.065]} scale={1.818} />
            <mesh geometry={nodes.Object_1086.geometry} material={materials['Material.001']} position={[2.191, 0, 1.057]} rotation={[-0.278, -0.249, -0.124]} scale={1.364} />
            <mesh geometry={nodes.Object_1088.geometry} material={materials['Material.001']} position={[2.156, 0, 1.113]} rotation={[0.141, -0.494, 0.013]} scale={1.124} />
            <mesh geometry={nodes.Object_1090.geometry} material={materials['Material.001']} position={[1.845, 0, 0.815]} rotation={[-3.048, -1.153, -2.71]} scale={1.941} />
            <mesh geometry={nodes.Object_1092.geometry} material={materials['Material.001']} position={[1.856, 0, 1.079]} rotation={[-0.091, 0.108, -0.06]} scale={1.345} />
            <mesh geometry={nodes.Object_1094.geometry} material={materials['Material.001']} position={[1.759, 0, 1.087]} rotation={[-3.087, 1.045, 2.957]} scale={0.986} />
            <mesh geometry={nodes.Object_1096.geometry} material={materials['Material.001']} position={[1.689, 0, 1.132]} rotation={[-0.336, -0.948, -0.455]} scale={0.833} />
            <mesh geometry={nodes.Object_1098.geometry} material={materials['Material.001']} position={[1.164, 0, 0.938]} rotation={[0.189, 0.956, 0.093]} scale={1.852} />
            <mesh geometry={nodes.Object_1100.geometry} material={materials['Material.001']} position={[1.494, 0, 1.014]} rotation={[-1.846, 1.393, 1.838]} scale={1.104} />
            <mesh geometry={nodes.Object_1102.geometry} material={materials['Material.001']} position={[-0.962, 0, 2.882]} rotation={[-0.168, 0.655, 0.045]} scale={1.839} />
            <mesh geometry={nodes.Object_1104.geometry} material={materials['Material.001']} position={[1.279, 0, 1.008]} rotation={[2.267, 1.47, -2.124]} scale={0.945} />
            <mesh geometry={nodes.Object_1106.geometry} material={materials['Material.001']} position={[1.27, 0, 1.06]} rotation={[0.151, 1.032, -0.415]} scale={1.309} />
            <mesh geometry={nodes.Object_1108.geometry} material={materials['Material.001']} position={[1.325, 0, 1.138]} rotation={[-2.633, -1.215, -2.919]} scale={1.733} />
            <mesh geometry={nodes.Object_1110.geometry} material={materials['Material.001']} position={[1.128, 0, 0.903]} rotation={[2.929, -0.465, 3.107]} scale={1.129} />
            <mesh geometry={nodes.Object_1112.geometry} material={materials['Material.001']} position={[0.96, 0, 0.971]} rotation={[-3.048, -0.225, -2.841]} scale={1.795} />
            <mesh geometry={nodes.Object_1114.geometry} material={materials['Material.001']} position={[0.967, 0, 1.038]} rotation={[0.8, 1.397, -0.694]} scale={0.913} />
            <mesh geometry={nodes.Object_1116.geometry} material={materials['Material.001']} position={[1.132, 0, 1.013]} rotation={[-3.115, 0.053, 2.813]} scale={1.417} />
            <mesh geometry={nodes.Object_1118.geometry} material={materials['Material.001']} position={[0.487, 0, 0.895]} rotation={[0.082, 0.025, 0.118]} scale={1.699} />
            <mesh geometry={nodes.Object_1120.geometry} material={materials['Material.001']} position={[0.701, 0, 0.935]} rotation={[-3.071, -0.295, -2.935]} scale={1.491} />
            <mesh geometry={nodes.Object_1122.geometry} material={materials['Material.001']} position={[0.692, 0, 0.988]} rotation={[0.312, -0.43, 0.001]} scale={1.911} />
            <mesh geometry={nodes.Object_1124.geometry} material={materials['Material.001']} position={[0.415, 0, 0.988]} rotation={[0.02, 0.203, 0.131]} scale={0.866} />
            <mesh geometry={nodes.Object_1126.geometry} material={materials['Material.001']} position={[0.519, 0, 1.007]} rotation={[-3.028, 0.766, 3.118]} scale={1.178} />
            <mesh geometry={nodes.Object_1128.geometry} material={materials['Material.001']} position={[-1.021, 0, 2.994]} rotation={[-3.133, 0.777, 2.849]} scale={1.663} />
            <mesh geometry={nodes.Object_1130.geometry} material={materials['Material.001']} position={[-2.963, 0, -1.031]} rotation={[0.365, 1.06, -0.471]} scale={1.451} />
            <mesh geometry={nodes.Object_1132.geometry} material={materials['Material.001']} position={[-1.439, 0, 2.821]} rotation={[0.296, 1.284, -0.233]} scale={1.092} />
            <mesh geometry={nodes.Object_1134.geometry} material={materials['Material.001']} position={[0.303, 0, 1.032]} rotation={[0.095, 1.231, -0.226]} scale={1.173} />
            <mesh geometry={nodes.Object_1136.geometry} material={materials['Material.001']} position={[-1.266, 0, 2.927]} rotation={[2.991, -0.157, 3.118]} scale={1.731} />
            <mesh geometry={nodes.Object_1138.geometry} material={materials['Material.001']} position={[0.355, 0, 1.089]} rotation={[-3.015, 0.21, 2.848]} scale={1.847} />
            <mesh geometry={nodes.Object_1140.geometry} material={materials['Material.001']} position={[-1.163, 0, 2.986]} rotation={[2.815, 0.743, -2.662]} scale={0.933} />
            <mesh geometry={nodes.Object_1142.geometry} material={materials['Material.001']} position={[-1.749, 0, 2.679]} rotation={[0.162, -0.267, -0.073]} scale={0.84} />
            <mesh geometry={nodes.Object_1144.geometry} material={materials['Material.001']} position={[-1.083, -0.009, -0.994]} rotation={[-Math.PI, 0.886, -Math.PI]} scale={0.493} />
            <mesh geometry={nodes.Object_1146.geometry} material={materials['Material.001']} position={[-1.746, 0, 2.78]} rotation={[-3.106, 0.661, 3.065]} scale={1.87} />
            <mesh geometry={nodes.Object_1148.geometry} material={materials['Material.001']} position={[-0.371, 0, 1.022]} rotation={[-0.158, 0.763, 0.06]} scale={1.378} />
            <mesh geometry={nodes.Object_1150.geometry} material={materials['Material.001']} position={[-0.585, 0, 0.817]} rotation={[3.061, -0.102, 3.133]} scale={1.023} />
            <mesh geometry={nodes.Object_1152.geometry} material={materials['Material.001']} position={[-0.626, 0, 0.909]} rotation={[2.672, 1.439, -2.82]} scale={1.439} />
            <mesh geometry={nodes.Object_1154.geometry} material={materials['Material.001']} position={[-0.473, 0, 0.787]} rotation={[0.021, -0.815, -0.016]} scale={1.963} />
            <mesh geometry={nodes.Object_1156.geometry} material={materials['Material.001']} position={[-0.406, 0, 1.139]} rotation={[-0.163, -0.409, -0.151]} scale={0.976} />
            <mesh geometry={nodes.Object_1158.geometry} material={materials['Material.001']} position={[-0.512, 0, 1.138]} rotation={[0.305, 0.667, -0.328]} scale={1.283} />
            <mesh geometry={nodes.Object_1160.geometry} material={materials['Material.001']} position={[-0.655, 0, 1.106]} rotation={[0.13, 1.008, 0.078]} scale={1.462} />
            <mesh geometry={nodes.Object_1162.geometry} material={materials['Material.001']} position={[-0.903, 0, 0.98]} rotation={[2.031, 1.28, -1.835]} scale={1.344} />
            <mesh geometry={nodes.Object_1164.geometry} material={materials['Material.001']} position={[-0.861, 0, 1.08]} rotation={[2.698, -1.186, 2.677]} scale={1.048} />
            <mesh geometry={nodes.Object_1166.geometry} material={materials['Material.001']} position={[-1.126, 0, 1.002]} rotation={[0.158, -0.036, 0.298]} scale={1.188} />
            <mesh geometry={nodes.Object_1168.geometry} material={materials['Material.001']} position={[-1.031, 0, 0.984]} rotation={[-0.002, -1.219, 0.059]} scale={1.527} />
            <mesh geometry={nodes.Object_1170.geometry} material={materials['Material.001']} position={[-1.48, 0, 0.807]} rotation={[-0.001, 0.888, -0.007]} scale={1.461} />
            <mesh geometry={nodes.Object_1172.geometry} material={materials['Material.001']} position={[-1.394, 0, 0.806]} rotation={[2.832, -0.967, 2.845]} scale={1.66} />
            <mesh geometry={nodes.Object_1174.geometry} material={materials['Material.001']} position={[-1.176, 0, 1.128]} rotation={[0.087, 0.134, 0.183]} scale={1.097} />
            <mesh geometry={nodes.Object_1176.geometry} material={materials['Material.001']} position={[-1.275, 0, 1.077]} rotation={[-2.932, 0.093, -3.084]} scale={1.873} />
            <mesh geometry={nodes.Object_1178.geometry} material={materials['Material.001']} position={[-1.772, 0, 0.915]} rotation={[0.02, -0.907, 0.005]} scale={1.818} />
            <mesh geometry={nodes.Object_1180.geometry} material={materials['Material.001']} position={[-1.578, 0, 0.795]} rotation={[-2.885, 0.048, -3.064]} scale={1.491} />
            <mesh geometry={nodes.Object_1182.geometry} material={materials['Material.001']} position={[-2.121, 0, 0.92]} rotation={[-2.908, -1.301, -2.956]} scale={1.106} />
            <mesh geometry={nodes.Object_1184.geometry} material={materials['Material.001']} position={[-1.941, 0, 0.879]} rotation={[0.152, -0.91, 0.128]} scale={1.432} />
            <mesh geometry={nodes.Object_1186.geometry} material={materials['Material.001']} position={[-2.007, 0, 1.128]} rotation={[-3.082, 0.522, 3.139]} scale={1.081} />
            <mesh geometry={nodes.Object_1188.geometry} material={materials['Material.001']} position={[-2.199, 0, 1.022]} rotation={[0.218, -0.141, -0.026]} scale={0.852} />
            <mesh geometry={nodes.Object_1190.geometry} material={materials['Material.001']} position={[-2.581, 0, 0.855]} rotation={[-3.136, -1.178, -2.952]} scale={1.264} />
            <mesh geometry={nodes.Object_1192.geometry} material={materials['Material.001']} position={[-2.375, 0, 0.872]} rotation={[-2.835, 0.267, 3.14]} scale={1.982} />
            <mesh geometry={nodes.Object_1194.geometry} material={materials['Material.001']} position={[2.328, 0, 1.155]} rotation={[0.041, -1.196, 0.241]} scale={1.668} />
            <mesh geometry={nodes.Object_1196.geometry} material={materials['Material.001']} position={[2.551, 0, 1.233]} rotation={[2.867, -0.134, -3.117]} scale={1.126} />
            <mesh geometry={nodes.Object_1198.geometry} material={materials['Material.001']} position={[2.642, 0, 1.472]} rotation={[-2.819, -0.95, -3.054]} scale={1.4} />
            <mesh geometry={nodes.Object_1200.geometry} material={materials['Material.001']} position={[2.469, 0, 1.5]} rotation={[0.035, 0.841, -0.265]} scale={1.89} />
            <mesh geometry={nodes.Object_1202.geometry} material={materials['Material.001']} position={[-1.566, 0, 2.661]} rotation={[0.012, 0.421, 0.113]} scale={1.941} />
            <mesh geometry={nodes.Object_1204.geometry} material={materials['Material.001']} position={[2.356, 0, 1.434]} rotation={[-0.477, -1.227, -0.613]} scale={1.04} />
            <mesh geometry={nodes.Object_1206.geometry} material={materials['Material.001']} position={[2.028, 0, 1.262]} rotation={[2.822, 0.76, -2.828]} scale={0.875} />
            <mesh geometry={nodes.Object_1208.geometry} material={materials['Material.001']} position={[2.193, 0, 1.262]} rotation={[-0.27, -1.215, 0.056]} scale={1.684} />
            <mesh geometry={nodes.Object_1210.geometry} material={materials['Material.001']} position={[1.922, 0, 1.402]} rotation={[-1.273, 1.364, 1.335]} scale={1.136} />
            <mesh geometry={nodes.Object_1212.geometry} material={materials['Material.001']} position={[1.626, 0, 1.193]} rotation={[0.044, 0.273, -0.163]} scale={1.987} />
            <mesh geometry={nodes.Object_1214.geometry} material={materials['Material.001']} position={[-1.897, 0, 2.858]} rotation={[-3.01, -0.183, 2.908]} scale={1.209} />
            <mesh geometry={nodes.Object_1216.geometry} material={materials['Material.001']} position={[1.782, 0, 1.284]} rotation={[0.201, 0.608, -0.259]} scale={1.927} />
            <mesh geometry={nodes.Object_1218.geometry} material={materials['Material.001']} position={[1.88, 0, 1.346]} rotation={[2.619, -0.987, 2.652]} scale={1.904} />
            <mesh geometry={nodes.Object_1220.geometry} material={materials['Material.001']} position={[-1.834, 0, 2.937]} rotation={[2.693, 1.226, -2.631]} scale={1.228} />
            <mesh geometry={nodes.Object_1222.geometry} material={materials['Material.001']} position={[1.234, 0, 1.299]} rotation={[-0.228, -0.187, -0.205]} scale={0.844} />
            <mesh geometry={nodes.Object_1224.geometry} material={materials['Material.001']} position={[1.501, 0, 1.267]} rotation={[2.661, 0.9, -2.598]} scale={1.23} />
            <mesh geometry={nodes.Object_1226.geometry} material={materials['Material.001']} position={[1.506, 0, 1.408]} rotation={[0.039, 0.148, -0.101]} scale={1.832} />
            <mesh geometry={nodes.Object_1228.geometry} material={materials['Material.001']} position={[1.38, 0, 1.486]} rotation={[0.039, 0.082, -0.103]} scale={1.75} />
            <mesh geometry={nodes.Object_1230.geometry} material={materials['Material.001']} position={[0.962, 0, 1.266]} rotation={[-2.878, -0.279, -2.866]} scale={1.265} />
            <mesh geometry={nodes.Object_1232.geometry} material={materials['Material.001']} position={[1.019, 0, 1.401]} rotation={[-2.876, 0.857, 3.039]} scale={1.81} />
            <mesh geometry={nodes.Object_1234.geometry} material={materials['Material.001']} position={[1.106, 0, 1.346]} rotation={[2.939, -0.533, 3.02]} scale={1.592} />
            <mesh geometry={nodes.Object_1236.geometry} material={materials['Material.001']} position={[0.829, 0, 1.428]} rotation={[-3.079, 0.196, 2.936]} scale={0.994} />
            <mesh geometry={nodes.Object_1238.geometry} material={materials['Material.001']} position={[0.727, 0, 1.287]} rotation={[-2.897, -1.38, -2.99]} scale={1.925} />
            <mesh geometry={nodes.Object_1240.geometry} material={materials['Material.001']} position={[0.652, 0, 1.271]} rotation={[-0.255, -1.236, -0.166]} scale={1.127} />
            <mesh geometry={nodes.Object_1242.geometry} material={materials['Material.001']} position={[0.167, 0, 1.175]} rotation={[-3.075, -0.698, -3.081]} scale={1.967} />
            <mesh geometry={nodes.Object_1244.geometry} material={materials['Material.001']} position={[-1.768, 0, 2.897]} rotation={[0.118, 0.793, 0.026]} scale={0.897} />
            <mesh geometry={nodes.Object_1246.geometry} material={materials['Material.001']} position={[0.287, 0, 1.242]} rotation={[0.089, 0.525, 0.157]} scale={1.307} />
            <mesh geometry={nodes.Object_1248.geometry} material={materials['Material.001']} position={[-1.878, 0, 3.009]} rotation={[1.507, 1.352, -1.363]} scale={1.061} />
            <mesh geometry={nodes.Object_1250.geometry} material={materials['Material.001']} position={[-2.111, 0, 2.815]} rotation={[0.067, -0.398, 0.154]} scale={1.784} />
            <mesh geometry={nodes.Object_1252.geometry} material={materials['Material.001']} position={[-2.067, 0, 2.689]} rotation={[2.757, -0.603, 3.05]} scale={1.502} />
            <mesh geometry={nodes.Object_1254.geometry} material={materials['Material.001']} position={[-1.942, 0, 2.678]} rotation={[0.08, 0.931, -0.028]} scale={1.062} />
            <mesh geometry={nodes.Object_1256.geometry} material={materials['Material.001']} position={[-1.93, -0.009, -0.545]} rotation={[-Math.PI, 1.507, -Math.PI]} scale={0.673} />
            <mesh geometry={nodes.Object_1258.geometry} material={materials['Material.001']} position={[-0.637, 0, 1.205]} rotation={[0.124, 0.096, 0.235]} scale={1.55} />
            <mesh geometry={nodes.Object_1260.geometry} material={materials['Material.001']} position={[-0.419, 0, 1.414]} rotation={[-3.05, 0.361, 2.841]} scale={1.811} />
            <mesh geometry={nodes.Object_1262.geometry} material={materials['Material.001']} position={[-0.51, 0, 1.491]} rotation={[-3.065, 0.101, 2.808]} scale={0.97} />
            <mesh geometry={nodes.Object_1264.geometry} material={materials['Material.001']} position={[-0.577, 0, 1.443]} rotation={[0.049, 0.213, 0.225]} scale={1.55} />
            <mesh geometry={nodes.Object_1266.geometry} material={materials['Material.001']} position={[-0.891, 0, 1.183]} rotation={[2.796, 0.644, -2.739]} scale={1.686} />
            <mesh geometry={nodes.Object_1268.geometry} material={materials['Material.001']} position={[-0.879, 0, 1.28]} rotation={[-3.117, 0.526, 3.096]} scale={1.229} />
            <mesh geometry={nodes.Object_1270.geometry} material={materials['Material.001']} position={[-1.052, 0, 1.372]} rotation={[0.101, 1.009, -0.113]} scale={0.85} />
            <mesh geometry={nodes.Object_1272.geometry} material={materials['Material.001']} position={[-0.949, 0, 1.45]} rotation={[-2.98, -0.689, -2.953]} scale={1.272} />
            <mesh geometry={nodes.Object_1274.geometry} material={materials['Material.001']} position={[-1.194, 0, 1.367]} rotation={[-2.757, 1.228, 2.838]} scale={1.606} />
            <mesh geometry={nodes.Object_1276.geometry} material={materials['Material.001']} position={[-1.921, 0, 2.799]} rotation={[-2.941, 0.822, 3.067]} scale={1.657} />
            <mesh geometry={nodes.Object_1278.geometry} material={materials['Material.001']} position={[-1.476, 0, 1.401]} rotation={[-0.001, -1.331, -0.002]} scale={1.909} />
            <mesh geometry={nodes.Object_1280.geometry} material={materials['Material.001']} position={[-1.823, 0, 1.251]} rotation={[3.061, 0.667, -3.079]} scale={1.81} />
            <mesh geometry={nodes.Object_1282.geometry} material={materials['Material.001']} position={[-2.175, 0, 2.963]} rotation={[0.695, 1.246, -0.514]} scale={0.893} />
            <mesh geometry={nodes.Object_1284.geometry} material={materials['Material.001']} position={[-1.54, 0, 1.276]} rotation={[0.134, -0.622, -0.006]} scale={1.58} />
            <mesh geometry={nodes.Object_1286.geometry} material={materials['Material.001']} position={[-1.591, 0, 1.414]} rotation={[0.04, -0.878, 0.121]} scale={1.678} />
            <mesh geometry={nodes.Object_1288.geometry} material={materials['Material.001']} position={[-1.887, 0, 1.462]} rotation={[2.975, 1.372, 3.068]} scale={1.305} />
            <mesh geometry={nodes.Object_1290.geometry} material={materials['Material.001']} position={[-2.159, 0, 1.165]} rotation={[-0.38, -1.254, -0.418]} scale={1.658} />
            <mesh geometry={nodes.Object_1292.geometry} material={materials['Material.001']} position={[-2.246, 0, 1.309]} rotation={[0.088, -0.352, 0.018]} scale={1.672} />
            <mesh geometry={nodes.Object_1294.geometry} material={materials['Material.001']} position={[-2.034, 0, 1.434]} rotation={[0.054, 0.473, -0.202]} scale={1.288} />
            <mesh geometry={nodes.Object_1296.geometry} material={materials['Material.001']} position={[-2.138, 0, 1.478]} rotation={[-3.102, -0.987, 3.072]} scale={1.98} />
            <mesh geometry={nodes.Object_1298.geometry} material={materials['Material.001']} position={[-2.241, 0, 1.491]} rotation={[-2.668, 0.937, 2.828]} scale={1.115} />
            <mesh geometry={nodes.Object_1300.geometry} material={materials['Material.001']} position={[-2.656, 0, 1.262]} rotation={[1.717, 1.447, -1.518]} scale={0.864} />
            <mesh geometry={nodes.Object_1302.geometry} material={materials['Material.001']} position={[-2.475, 0, 2.777]} rotation={[-0.29, -0.577, -0.379]} scale={1.148} />
            <mesh geometry={nodes.Object_1304.geometry} material={materials['Material.001']} position={[-2.46, 0, 1.254]} rotation={[1.574, 1.422, -1.476]} scale={1.44} />
            <mesh geometry={nodes.Object_1306.geometry} material={materials['Material.001']} position={[-2.362, 0, 1.341]} rotation={[3.139, 0.235, 3.128]} scale={1.199} />
            <mesh geometry={nodes.Object_1308.geometry} material={materials['Material.001']} position={[-2.481, 0, 1.341]} rotation={[-0.983, 1.283, 0.902]} scale={1.429} />
            <mesh geometry={nodes.Object_1310.geometry} material={materials['Material.001']} position={[-2.493, 0, 1.483]} rotation={[3.131, -1.418, -3.089]} scale={1.087} />
            <mesh geometry={nodes.Object_1312.geometry} material={materials['Material.001']} position={[2.613, 0, 1.592]} rotation={[0.815, -1.142, 0.74]} scale={1.531} />
            <mesh geometry={nodes.Object_1314.geometry} material={materials['Material.001']} position={[2.093, 0, 1.57]} rotation={[1.464, 1.414, -1.348]} scale={1.828} />
            <mesh geometry={nodes.Object_1316.geometry} material={materials['Material.001']} position={[2.105, 0, 1.707]} rotation={[-2.405, 1.53, 2.42]} scale={1.854} />
            <mesh geometry={nodes.Object_1318.geometry} material={materials['Material.001']} position={[2.176, 0, 1.765]} rotation={[3.07, -0.573, 3.094]} scale={1.247} />
            <mesh geometry={nodes.Object_1320.geometry} material={materials['Material.001']} position={[-2.459, 0, 2.716]} rotation={[2.85, -0.685, 2.947]} scale={1.757} />
            <mesh geometry={nodes.Object_1322.geometry} material={materials['Material.001']} position={[1.994, 0, 1.826]} rotation={[-3.115, 0.023, -3.045]} scale={0.981} />
            <mesh geometry={nodes.Object_1324.geometry} material={materials['Material.001']} position={[-2.373, 0, 2.863]} rotation={[-0.047, 1.138, 0.032]} scale={1.887} />
            <mesh geometry={nodes.Object_1326.geometry} material={materials['Material.001']} position={[1.54, 0, 1.66]} rotation={[0.07, 0.387, -0.219]} scale={1.379} />
            <mesh geometry={nodes.Object_1328.geometry} material={materials['Material.001']} position={[1.584, 0, 1.688]} rotation={[0.003, -0.275, 0.254]} scale={1.186} />
            <mesh geometry={nodes.Object_1330.geometry} material={materials['Material.001']} position={[1.752, 0, 1.637]} rotation={[-0.274, -0.363, -0.292]} scale={1.765} />
            <mesh geometry={nodes.Object_1332.geometry} material={materials['Material.001']} position={[1.829, 0, 1.749]} rotation={[0.299, 0.031, -0.125]} scale={1.302} />
            <mesh geometry={nodes.Object_1334.geometry} material={materials['Material.001']} position={[1.868, 0, 1.894]} rotation={[2.909, 0.291, -2.931]} scale={1.079} />
            <mesh geometry={nodes.Object_1336.geometry} material={materials['Material.001']} position={[1.73, 0, 1.815]} rotation={[-0.663, -1.252, -0.906]} scale={0.892} />
            <mesh geometry={nodes.Object_1338.geometry} material={materials['Material.001']} position={[1.681, 0, 1.806]} rotation={[0.024, -0.342, -0.056]} scale={1.395} />
            <mesh geometry={nodes.Object_1340.geometry} material={materials['Material.001']} position={[1.289, 0, 1.638]} rotation={[2.931, 0.108, -3.092]} scale={1.072} />
            <mesh geometry={nodes.Object_1342.geometry} material={materials['Material.001']} position={[1.362, 0, 1.57]} rotation={[-2.763, 1.465, 2.786]} scale={1.885} />
            <mesh geometry={nodes.Object_1344.geometry} material={materials['Material.001']} position={[1.447, 0, 1.847]} rotation={[-2.941, -0.37, -2.941]} scale={1.734} />
            <mesh geometry={nodes.Object_1346.geometry} material={materials['Material.001']} position={[1.309, 0, 1.795]} rotation={[0.054, -0.575, 0.03]} scale={1.822} />
            <mesh geometry={nodes.Object_1348.geometry} material={materials['Material.001']} position={[-2.478, 0, 3.017]} rotation={[-3.003, -0.869, -2.996]} scale={1.905} />
            <mesh geometry={nodes.Object_1350.geometry} material={materials['Material.001']} position={[0.856, 0, 1.545]} rotation={[-3.007, -0.608, 2.888]} scale={1.579} />
            <mesh geometry={nodes.Object_1352.geometry} material={materials['Material.001']} position={[0.988, 0, 1.583]} rotation={[2.998, -0.388, 3.077]} scale={1.525} />
            <mesh geometry={nodes.Object_1354.geometry} material={materials['Material.001']} position={[1.101, 0, 1.744]} rotation={[0.087, 0.212, -0.226]} scale={1.677} />
            <mesh geometry={nodes.Object_1356.geometry} material={materials['Material.001']} position={[-2.848, 0, 2.745]} rotation={[-2.887, 0.388, -3.057]} scale={0.996} />
            <mesh geometry={nodes.Object_1358.geometry} material={materials['Material.001']} position={[1.129, 0, 1.863]} rotation={[-3.061, -0.581, 3.019]} scale={1.398} />
            <mesh geometry={nodes.Object_1360.geometry} material={materials['Material.001']} position={[1.029, 0, 1.809]} rotation={[-0.121, -1.119, -0.063]} scale={1.833} />
            <mesh geometry={nodes.Object_1362.geometry} material={materials['Material.001']} position={[0.93, 0, 1.818]} rotation={[-2.907, -0.156, -3.009]} scale={1.776} />
            <mesh geometry={nodes.Object_1364.geometry} material={materials['Material.001']} position={[0.605, 0, 1.615]} rotation={[0.017, 0.243, -0.082]} scale={1.532} />
            <mesh geometry={nodes.Object_1366.geometry} material={materials['Material.001']} position={[0.728, 0, 1.785]} rotation={[-2.52, -1.314, -2.645]} scale={1.911} />
            <mesh geometry={nodes.Object_1368.geometry} material={materials['Material.001']} position={[0.389, 0, 1.868]} rotation={[3.141, -0.112, 3.139]} scale={1.094} />
            <mesh geometry={nodes.Object_1370.geometry} material={materials['Material.001']} position={[0.248, 0, 1.522]} rotation={[-1.82, 1.229, 1.809]} scale={1.075} />
            <mesh geometry={nodes.Object_1372.geometry} material={materials['Material.001']} position={[0.367, 0, 1.614]} rotation={[-3.116, -0.016, -3.047]} scale={1.612} />
            <mesh geometry={nodes.Object_1374.geometry} material={materials['Material.001']} position={[-2.893, 0, 2.801]} rotation={[-0.061, 0.141, -0.043]} scale={0.944} />
            <mesh geometry={nodes.Object_1376.geometry} material={materials['Material.001']} position={[0.287, 0, 1.778]} rotation={[2.962, 1.129, 3.011]} scale={0.963} />
            <mesh geometry={nodes.Object_1378.geometry} material={materials['Material.001']} position={[0.184, 0, 1.747]} rotation={[-0.041, 1.07, 0.026]} scale={1.672} />
            <mesh geometry={nodes.Object_1380.geometry} material={materials['Material.001']} position={[0.137, 0, 1.836]} rotation={[-3.086, 0.013, 3.069]} scale={1.97} />
            <mesh geometry={nodes.Object_1382.geometry} material={materials['Material.001']} position={[0.079, 0, 1.83]} rotation={[1.034, -1.232, 0.943]} scale={1.264} />
            <mesh geometry={nodes.Object_1384.geometry} material={materials['Material.001']} position={[-0.349, 0, 1.569]} rotation={[-0.35, 0.528, 0.067]} scale={1.784} />
            <mesh geometry={nodes.Object_1386.geometry} material={materials['Material.001']} position={[-0.076, 0, 1.813]} rotation={[-3.119, -0.178, -3.134]} scale={1.179} />
            <mesh geometry={nodes.Object_1388.geometry} material={materials['Material.001']} position={[-0.217, 0, 1.819]} rotation={[0.038, -0.098, 0.2]} scale={1.699} />
            <mesh geometry={nodes.Object_1390.geometry} material={materials['Material.001']} position={[-0.536, 0, 1.675]} rotation={[2.586, -1.355, 2.62]} scale={0.992} />
            <mesh geometry={nodes.Object_1392.geometry} material={materials['Material.001']} position={[-0.682, 0, 1.789]} rotation={[-0.166, -0.469, -0.224]} scale={1.931} />
            <mesh geometry={nodes.Object_1394.geometry} material={materials['Material.001']} position={[-0.58, 0, 1.771]} rotation={[0.173, 0.237, 0.222]} scale={0.896} />
            <mesh geometry={nodes.Object_1396.geometry} material={materials['Material.001']} position={[-1.107, 0, 1.59]} rotation={[-0.057, 1.387, -0.02]} scale={0.856} />
            <mesh geometry={nodes.Object_1398.geometry} material={materials['Material.001']} position={[-0.846, 0, 1.565]} rotation={[2.917, 0.865, -2.855]} scale={1.802} />
            <mesh geometry={nodes.Object_1400.geometry} material={materials['Material.001']} position={[-0.855, 0, 1.706]} rotation={[-3.091, 0.158, 2.817]} scale={1.067} />
            <mesh geometry={nodes.Object_1402.geometry} material={materials['Material.001']} position={[-0.814, 0, 1.78]} rotation={[3.043, 0.364, -3.007]} scale={1.715} />
            <mesh geometry={nodes.Object_1404.geometry} material={materials['Material.001']} position={[-2.942, 0, -0.764]} rotation={[2.935, -0.212, -3.051]} scale={1.2} />
            <mesh geometry={nodes.Object_1406.geometry} material={materials['Material.001']} position={[-0.997, 0, 1.822]} rotation={[0.063, 1.069, -0.078]} scale={1.378} />
            <mesh geometry={nodes.Object_1408.geometry} material={materials['Material.001']} position={[-1.329, 0, 1.552]} rotation={[0.011, 0.685, -0.127]} scale={1.834} />
            <mesh geometry={nodes.Object_1410.geometry} material={materials['Material.001']} position={[-1.514, 0, 1.772]} rotation={[0.031, 1.196, -0.209]} scale={1.167} />
            <mesh geometry={nodes.Object_1412.geometry} material={materials['Material.001']} position={[-1.774, 0, 1.644]} rotation={[0.002, -0.189, 0.002]} scale={1.716} />
            <mesh geometry={nodes.Object_1414.geometry} material={materials['Material.001']} position={[-1.874, 0, 1.651]} rotation={[-0.136, -1.177, 0.182]} scale={1.505} />
            <mesh geometry={nodes.Object_1416.geometry} material={materials['Material.001']} position={[-1.69, 0, 1.816]} rotation={[2.976, 0.818, -2.947]} scale={0.917} />
            <mesh geometry={nodes.Object_1418.geometry} material={materials['Material.001']} position={[-2.067, 0, 1.826]} rotation={[0.129, -0.487, -0.023]} scale={0.963} />
            <mesh geometry={nodes.Object_1420.geometry} material={materials['Material.001']} position={[-2.249, 0, 1.78]} rotation={[3.139, 1.023, 3.141]} scale={1.553} />
            <mesh geometry={nodes.Object_1422.geometry} material={materials['Material.001']} position={[-2.278, 0, 1.882]} rotation={[-0.265, -1.05, -0.296]} scale={1.653} />
            <mesh geometry={nodes.Object_1424.geometry} material={materials['Material.001']} position={[-2.606, 0, 1.719]} rotation={[0.103, 0.379, 0.272]} scale={1.248} />
            <mesh geometry={nodes.Object_1426.geometry} material={materials['Material.001']} position={[-2.604, 0, 1.791]} rotation={[-0.287, -1.478, -0.339]} scale={1.012} />
            <mesh geometry={nodes.Object_1428.geometry} material={materials['Material.001']} position={[2.306, 0, 2.001]} rotation={[0.004, 0, 0.149]} scale={0.982} />
            <mesh geometry={nodes.Object_1430.geometry} material={materials['Material.001']} position={[2.625, 0, 2.04]} rotation={[-0.182, 0.26, 0.028]} scale={1.258} />
            <mesh geometry={nodes.Object_1432.geometry} material={materials['Material.001']} position={[2.57, 0, 2.16]} rotation={[0.062, -0.75, 0.142]} scale={1.465} />
            <mesh geometry={nodes.Object_1434.geometry} material={materials['Material.001']} position={[2.592, 0, 2.259]} rotation={[-3.074, -0.468, -2.969]} scale={1.445} />
            <mesh geometry={nodes.Object_1436.geometry} material={materials['Material.001']} position={[2.31, 0, 2.265]} rotation={[3.086, 0.884, -3.045]} scale={0.87} />
            <mesh geometry={nodes.Object_1438.geometry} material={materials['Material.001']} position={[2.028, 0, 2.013]} rotation={[-0.199, 1.084, 0.143]} scale={1.828} />
            <mesh geometry={nodes.Object_1440.geometry} material={materials['Material.001']} position={[2.092, 0, 1.902]} rotation={[-0.049, 1.208, 0.047]} scale={1.308} />
            <mesh geometry={nodes.Object_1442.geometry} material={materials['Material.001']} position={[1.913, 0, 2.14]} rotation={[0.084, 0.077, -0.183]} scale={1.569} />
            <mesh geometry={nodes.Object_1444.geometry} material={materials['Material.001']} position={[2.015, 0, 2.239]} rotation={[-3.14, -0.315, 3.1]} scale={0.989} />
            <mesh geometry={nodes.Object_1446.geometry} material={materials['Material.001']} position={[-2.713, 0, 2.751]} rotation={[0.225, -0.756, 0.061]} scale={1.962} />
            <mesh geometry={nodes.Object_1448.geometry} material={materials['Material.001']} position={[1.698, 0, 2.055]} rotation={[2.657, 1.173, -2.469]} scale={1.824} />
            <mesh geometry={nodes.Object_1450.geometry} material={materials['Material.001']} position={[1.559, 0, 2.096]} rotation={[-3.029, 0.389, 2.784]} scale={0.962} />
            <mesh geometry={nodes.Object_1452.geometry} material={materials['Material.001']} position={[1.231, 0, 2.001]} rotation={[2.746, 0.838, -2.659]} scale={1.217} />
            <mesh geometry={nodes.Object_1454.geometry} material={materials['Material.001']} position={[1.361, 0, 2.106]} rotation={[-0.323, 0.107, -0.003]} scale={1.62} />
            <mesh geometry={nodes.Object_1456.geometry} material={materials['Material.001']} position={[0.857, 0, 1.912]} rotation={[-0.445, 1.095, 0.44]} scale={1.657} />
            <mesh geometry={nodes.Object_1458.geometry} material={materials['Material.001']} position={[-2.763, 0, 2.771]} rotation={[-3.052, -0.029, 2.917]} scale={1.539} />
            <mesh geometry={nodes.Object_1460.geometry} material={materials['Material.001']} position={[0.809, 0, 2.04]} rotation={[-0.22, -0.296, -0.107]} scale={1.568} />
            <mesh geometry={nodes.Object_1462.geometry} material={materials['Material.001']} position={[1.038, 0, 1.916]} rotation={[-0.192, 0.511, 0.078]} scale={1.172} />
            <mesh geometry={nodes.Object_1464.geometry} material={materials['Material.001']} position={[1.09, 0, 2.02]} rotation={[1.018, 1.352, -0.81]} scale={1.373} />
            <mesh geometry={nodes.Object_1466.geometry} material={materials['Material.001']} position={[1.084, 0, 2.184]} rotation={[-3.051, -0.529, -2.802]} scale={1.239} />
            <mesh geometry={nodes.Object_1468.geometry} material={materials['Material.001']} position={[0.917, 0, 2.266]} rotation={[3.013, 1.041, 2.999]} scale={1.851} />
            <mesh geometry={nodes.Object_1470.geometry} material={materials['Material.001']} position={[0.682, 0, 1.966]} rotation={[-2.589, -1.372, -2.379]} scale={1.266} />
            <mesh geometry={nodes.Object_1472.geometry} material={materials['Material.001']} position={[0.74, 0, 2.253]} rotation={[-3.129, 0.726, 3.062]} scale={1.749} />
            <mesh geometry={nodes.Object_1474.geometry} material={materials['Material.001']} position={[0.66, 0, 2.204]} rotation={[3.078, 1.29, -3.124]} scale={1.348} />
            <mesh geometry={nodes.Object_1476.geometry} material={materials['Material.001']} position={[0.568, 0, 2.093]} rotation={[-2.804, 1.493, 2.82]} scale={0.993} />
            <mesh geometry={nodes.Object_1478.geometry} material={materials['Material.001']} position={[-2.98, 0, 2.901]} rotation={[-3.127, -0.757, 3.085]} scale={1.954} />
            <mesh geometry={nodes.Object_1480.geometry} material={materials['Material.001']} position={[0.209, 0, 2.027]} rotation={[-2.905, -1.081, -2.655]} scale={1.732} />
            <mesh geometry={nodes.Object_1482.geometry} material={materials['Material.001']} position={[0.277, 0, 2.096]} rotation={[2.671, 1.504, -2.654]} scale={1.002} />
            <mesh geometry={nodes.Object_1484.geometry} material={materials['Material.001']} position={[0.048, 0, 2.094]} rotation={[-0.013, -0.819, 0.055]} scale={1.167} />
            <mesh geometry={nodes.Object_1486.geometry} material={materials['Material.001']} position={[0.167, 0, 2.257]} rotation={[-2.709, -1.477, -2.783]} scale={1.014} />
            <mesh geometry={nodes.Object_1488.geometry} material={materials['Material.001']} position={[0.017, 0, 2.241]} rotation={[0.1, 0.547, -0.121]} scale={1.263} />
            <mesh geometry={nodes.Object_1490.geometry} material={materials['Material.001']} position={[-3.018, 0, 3.009]} rotation={[0.312, 0.365, -0.246]} scale={1.56} />
            <mesh geometry={nodes.Object_1492.geometry} material={materials['Material.001']} position={[-0.161, 0, 1.919]} rotation={[2.994, -0.161, -3.09]} scale={1.172} />
            <mesh geometry={nodes.Object_1494.geometry} material={materials['Material.001']} position={[-0.171, 0, 1.98]} rotation={[0.679, -1.066, 0.62]} scale={1.007} />
            <mesh geometry={nodes.Object_1496.geometry} material={materials['Material.001']} position={[-0.001, 0, 1.902]} rotation={[0.14, 0.898, 0.051]} scale={1.329} />
            <mesh geometry={nodes.Object_1498.geometry} material={materials['Material.001']} position={[-0.034, 0, 2.051]} rotation={[-0.034, 0.326, 0.015]} scale={1.004} />
            <mesh geometry={nodes.Object_1500.geometry} material={materials['Material.001']} position={[-0.525, 0, 1.925]} rotation={[0.073, 0.575, 0.036]} scale={1.984} />
            <mesh geometry={nodes.Object_1502.geometry} material={materials['Material.001']} position={[-0.504, 0, 2.077]} rotation={[2.802, 0.694, -2.798]} scale={0.936} />
            <mesh geometry={nodes.Object_1504.geometry} material={materials['Material.001']} position={[2.362, 0, -2.603]} rotation={[-2.787, 0.645, 2.965]} scale={0.938} />
            <mesh geometry={nodes.Object_1506.geometry} material={materials['Material.001']} position={[-0.399, 0, 2.162]} rotation={[2.904, 1.175, -2.86]} scale={1.962} />
            <mesh geometry={nodes.Object_1508.geometry} material={materials['Material.001']} position={[-0.487, 0, 2.203]} rotation={[0.106, -0.76, 0.366]} scale={1.81} />
            <mesh geometry={nodes.Object_1510.geometry} material={materials['Material.001']} position={[-0.7, 0, 2.137]} rotation={[-3.088, -0.56, 3.034]} scale={1.335} />
            <mesh geometry={nodes.Object_1512.geometry} material={materials['Material.001']} position={[-1.003, 0, 1.981]} rotation={[3.14, 0.33, 3.137]} scale={1.19} />
            <mesh geometry={nodes.Object_1514.geometry} material={materials['Material.001']} position={[-0.832, 0, 1.972]} rotation={[3.09, 0.657, 2.847]} scale={1.216} />
            <mesh geometry={nodes.Object_1516.geometry} material={materials['Material.001']} position={[-0.851, 0, 2.144]} rotation={[-0.467, -1.452, -0.492]} scale={1.969} />
            <mesh geometry={nodes.Object_1518.geometry} material={materials['Material.001']} position={[-0.836, 0, 2.268]} rotation={[2.162, -1.308, 2.109]} scale={1.147} />
            <mesh geometry={nodes.Object_1520.geometry} material={materials['Material.001']} position={[-1.472, 0, 1.9]} rotation={[-3.09, -0.532, -2.913]} scale={1.344} />
            <mesh geometry={nodes.Object_1522.geometry} material={materials['Material.001']} position={[-1.342, 0, 1.899]} rotation={[0.237, -0.512, -0.039]} scale={1.205} />
            <mesh geometry={nodes.Object_1524.geometry} material={materials['Material.001']} position={[-1.235, 0, 1.907]} rotation={[-0.002, -1.065, 0.151]} scale={1.136} />
            <mesh geometry={nodes.Object_1526.geometry} material={materials['Material.001']} position={[-1.23, 0, 2.027]} rotation={[-0.229, -0.51, -0.301]} scale={0.926} />
            <mesh geometry={nodes.Object_1528.geometry} material={materials['Material.001']} position={[-1.224, 0, 2.128]} rotation={[0.196, 0.044, -0.158]} scale={1.269} />
            <mesh geometry={nodes.Object_1530.geometry} material={materials['Material.001']} position={[-1.21, 0, 2.18]} rotation={[-3.093, -0.073, 2.829]} scale={1.793} />
            <mesh geometry={nodes.Object_1532.geometry} material={materials['Material.001']} position={[-1.3, 0, 2.195]} rotation={[0.148, 0.46, 0.105]} scale={1.75} />
            <mesh geometry={nodes.Object_1534.geometry} material={materials['Material.001']} position={[-1.532, 0, 1.973]} rotation={[-3.014, -1.137, -2.873]} scale={1.978} />
            <mesh geometry={nodes.Object_1536.geometry} material={materials['Material.001']} position={[-1.617, 0, 2.058]} rotation={[2.874, -0.014, -3.006]} scale={1.872} />
            <mesh geometry={nodes.Object_1538.geometry} material={materials['Material.001']} position={[-1.676, 0, 2.146]} rotation={[0.649, -1.221, 0.565]} scale={1.854} />
            <mesh geometry={nodes.Object_1540.geometry} material={materials['Material.001']} position={[-1.543, 0, 2.181]} rotation={[2.893, -0.035, -2.978]} scale={1.137} />
            <mesh geometry={nodes.Object_1542.geometry} material={materials['Material.001']} position={[-2.202, 0, 1.943]} rotation={[0.006, 0.226, -0.041]} scale={1.917} />
            <mesh geometry={nodes.Object_1544.geometry} material={materials['Material.001']} position={[2.505, 0, -2.425]} rotation={[3.125, 0.326, 3.033]} scale={1.176} />
            <mesh geometry={nodes.Object_1546.geometry} material={materials['Material.001']} position={[-2.185, 0, 2.068]} rotation={[-0.091, -1.008, 0.064]} scale={1.351} />
            <mesh geometry={nodes.Object_1548.geometry} material={materials['Material.001']} position={[-2.237, 0, 2.098]} rotation={[-3.064, -1.092, -2.753]} scale={1.071} />
            <mesh geometry={nodes.Object_1550.geometry} material={materials['Material.001']} position={[-2.564, 0, 2.002]} rotation={[3.111, 0.834, 2.966]} scale={0.874} />
            <mesh geometry={nodes.Object_1552.geometry} material={materials['Material.001']} position={[-2.427, 0, 2.003]} rotation={[-0.188, 0.749, 0.144]} scale={1.399} />
            <mesh geometry={nodes.Object_1554.geometry} material={materials['Material.001']} position={[-2.294, 0, 2.118]} rotation={[-3.033, -0.321, -2.905]} scale={1.068} />
            <mesh geometry={nodes.Object_1556.geometry} material={materials['Material.001']} position={[-2.965, 0, -0.841]} rotation={[-3.045, 0.574, 2.864]} scale={1.489} />
            <mesh geometry={nodes.Object_1558.geometry} material={materials['Material.001']} position={[2.477, 0, 2.432]} rotation={[-0.007, -0.073, -0.008]} scale={0.926} />
            <mesh geometry={nodes.Object_1560.geometry} material={materials['Material.001']} position={[2.48, 0, 2.585]} rotation={[-0.086, 0.367, -0.011]} scale={1.965} />
            <mesh geometry={nodes.Object_1562.geometry} material={materials['Material.001']} position={[2.538, 0, 2.654]} rotation={[-3.126, -0.526, 3.138]} scale={0.86} />
            <mesh geometry={nodes.Object_1564.geometry} material={materials['Material.001']} position={[2.403, 0, 2.543]} rotation={[-2.9, -0.029, -2.961]} scale={1.281} />
            <mesh geometry={nodes.Object_1566.geometry} material={materials['Material.001']} position={[1.948, 0, 2.296]} rotation={[2.694, 1.085, -2.492]} scale={1.38} />
            <mesh geometry={nodes.Object_1568.geometry} material={materials['Material.001']} position={[2.225, 0, 2.311]} rotation={[0.037, 0.433, 0.124]} scale={1.595} />
            <mesh geometry={nodes.Object_1570.geometry} material={materials['Material.001']} position={[2.232, 0, 2.375]} rotation={[-3.131, 0.483, -3.124]} scale={1.559} />
            <mesh geometry={nodes.Object_1572.geometry} material={materials['Material.001']} position={[2.254, 0, 2.429]} rotation={[1.608, 1.519, -1.506]} scale={1.961} />
            <mesh geometry={nodes.Object_1574.geometry} material={materials['Material.001']} position={[2.13, 0, 2.509]} rotation={[2.867, -1.043, 2.906]} scale={1.148} />
            <mesh geometry={nodes.Object_1576.geometry} material={materials['Material.001']} position={[2.016, 0, 2.53]} rotation={[2.992, 0.59, -3.029]} scale={1.695} />
            <mesh geometry={nodes.Object_1578.geometry} material={materials['Material.001']} position={[2.068, 0, 2.507]} rotation={[0.076, -1.135, 0.074]} scale={0.919} />
            <mesh geometry={nodes.Object_1580.geometry} material={materials['Material.001']} position={[1.705, 0, 2.464]} rotation={[0.38, -0.683, 0.217]} scale={0.847} />
            <mesh geometry={nodes.Object_1582.geometry} material={materials['Material.001']} position={[1.892, 0, 2.44]} rotation={[-0.399, -1.251, -0.567]} scale={1.729} />
            <mesh geometry={nodes.Object_1584.geometry} material={materials['Material.001']} position={[1.787, 0, 2.466]} rotation={[0.095, -0.152, 0.003]} scale={0.84} />
            <mesh geometry={nodes.Object_1586.geometry} material={materials['Material.001']} position={[1.756, 0, 2.508]} rotation={[-3.121, 0.152, 3.113]} scale={1.208} />
            <mesh geometry={nodes.Object_1588.geometry} material={materials['Material.001']} position={[1.778, 0, 2.649]} rotation={[0.78, -1.254, 0.761]} scale={1.554} />
            <mesh geometry={nodes.Object_1590.geometry} material={materials['Material.001']} position={[1.2, 0, 2.37]} rotation={[-3.074, -1.181, -3.098]} scale={1.828} />
            <mesh geometry={nodes.Object_1592.geometry} material={materials['Material.001']} position={[1.274, 0, 2.441]} rotation={[-0.011, -0.393, 0.151]} scale={1.233} />
            <mesh geometry={nodes.Object_1594.geometry} material={materials['Material.001']} position={[1.483, 0, 2.448]} rotation={[-0.471, -1.181, -0.597]} scale={1.69} />
            <mesh geometry={nodes.Object_1596.geometry} material={materials['Material.001']} position={[1.308, 0, 2.655]} rotation={[0.396, -0.639, 0.115]} scale={1.516} />
            <mesh geometry={nodes.Object_1598.geometry} material={materials['Material.001']} position={[0.858, 0, 2.452]} rotation={[2.618, -0.972, 2.672]} scale={1.927} />
            <mesh geometry={nodes.Object_1600.geometry} material={materials['Material.001']} position={[0.826, 0, 2.404]} rotation={[-3.068, -0.112, 3.059]} scale={1.504} />
            <mesh geometry={nodes.Object_1602.geometry} material={materials['Material.001']} position={[0.828, 0, 2.553]} rotation={[0, 0.246, 0.003]} scale={1.777} />
            <mesh geometry={nodes.Object_1604.geometry} material={materials['Material.001']} position={[0.382, 0, 2.347]} rotation={[0.18, -0.074, -0.049]} scale={1.778} />
            <mesh geometry={nodes.Object_1606.geometry} material={materials['Material.001']} position={[0.424, 0, 2.381]} rotation={[-3.076, -1.168, -2.807]} scale={0.844} />
            <mesh geometry={nodes.Object_1608.geometry} material={materials['Material.001']} position={[0.637, 0, 2.316]} rotation={[3.125, 0.649, -3.13]} scale={1.227} />
            <mesh geometry={nodes.Object_1610.geometry} material={materials['Material.001']} position={[0.688, 0, 2.294]} rotation={[0.102, -1.353, 0.201]} scale={1.662} />
            <mesh geometry={nodes.Object_1612.geometry} material={materials['Material.001']} position={[0.733, 0, 2.372]} rotation={[2.956, -1.168, 2.98]} scale={0.915} />
            <mesh geometry={nodes.Object_1614.geometry} material={materials['Material.001']} position={[0.443, 0, 2.535]} rotation={[0.093, -1.322, 0.179]} scale={1.188} />
            <mesh geometry={nodes.Object_1616.geometry} material={materials['Material.001']} position={[0.111, 0, 2.37]} rotation={[0.07, -0.017, 0.12]} scale={1.986} />
            <mesh geometry={nodes.Object_1618.geometry} material={materials['Material.001']} position={[0.165, 0, 2.405]} rotation={[-2.851, -1.371, -2.892]} scale={1.353} />
            <mesh geometry={nodes.Object_1620.geometry} material={materials['Material.001']} position={[0.289, 0, 2.429]} rotation={[-2.912, -1.217, -2.685]} scale={1.594} />
            <mesh geometry={nodes.Object_1622.geometry} material={materials['Material.001']} position={[0.357, 0, 2.425]} rotation={[0.116, 0.519, 0.062]} scale={1.047} />
            <mesh geometry={nodes.Object_1624.geometry} material={materials['Material.001']} position={[0.359, 0, 2.647]} rotation={[0.04, -0.314, 0.211]} scale={0.911} />
            <mesh geometry={nodes.Object_1626.geometry} material={materials['Material.001']} position={[0.03, 0, 2.53]} rotation={[-2.973, -0.614, -2.927]} scale={1.661} />
            <mesh geometry={nodes.Object_1628.geometry} material={materials['Material.001']} position={[0.108, 0, 2.543]} rotation={[0.106, -0.335, -0.047]} scale={0.885} />
            <mesh geometry={nodes.Object_1630.geometry} material={materials['Material.001']} position={[-0.362, 0, 2.32]} rotation={[2.831, 0.815, -2.7]} scale={0.881} />
            <mesh geometry={nodes.Object_1632.geometry} material={materials['Material.001']} position={[-0.216, 0, 2.439]} rotation={[-0.026, -0.989, -0.026]} scale={1.897} />
            <mesh geometry={nodes.Object_1634.geometry} material={materials['Material.001']} position={[-0.149, 0, 2.301]} rotation={[-0.046, 1.452, -0.014]} scale={1.227} />
            <mesh geometry={nodes.Object_1636.geometry} material={materials['Material.001']} position={[2.632, 0, -2.379]} rotation={[3.022, -0.121, -3.1]} scale={1.591} />
            <mesh geometry={nodes.Object_1638.geometry} material={materials['Material.001']} position={[-0.134, 0, 2.448]} rotation={[-0.355, 1.171, 0.348]} scale={1.783} />
            <mesh geometry={nodes.Object_1640.geometry} material={materials['Material.001']} position={[2.545, 0, -2.368]} rotation={[-2.798, -1.217, -2.568]} scale={1.43} />
            <mesh geometry={nodes.Object_1642.geometry} material={materials['Material.001']} position={[-0.571, 0, 2.365]} rotation={[0.426, 1.074, -0.139]} scale={1.57} />
            <mesh geometry={nodes.Object_1644.geometry} material={materials['Material.001']} position={[-0.658, 0, 2.394]} rotation={[-2.019, -1.463, -1.735]} scale={1.538} />
            <mesh geometry={nodes.Object_1646.geometry} material={materials['Material.001']} position={[-0.732, 0, 2.425]} rotation={[-2.913, 0.133, -3.024]} scale={1.666} />
            <mesh geometry={nodes.Object_1648.geometry} material={materials['Material.001']} position={[-0.532, 0, 2.292]} rotation={[0.035, -1.119, 0.021]} scale={1.158} />
            <mesh geometry={nodes.Object_1650.geometry} material={materials['Material.001']} position={[-0.512, 0, 2.361]} rotation={[2.378, -1.078, 2.507]} scale={0.916} />
            <mesh geometry={nodes.Object_1652.geometry} material={materials['Material.001']} position={[-0.41, 0, 2.39]} rotation={[-2.632, 1.465, 2.621]} scale={1.196} />
            <mesh geometry={nodes.Object_1654.geometry} material={materials['Material.001']} position={[-0.522, 0, 2.488]} rotation={[-2.676, -1.493, -2.694]} scale={1.148} />
            <mesh geometry={nodes.Object_1656.geometry} material={materials['Material.001']} position={[-0.767, 0, 2.568]} rotation={[3.011, -1.267, 3]} scale={1.606} />
            <mesh geometry={nodes.Object_1658.geometry} material={materials['Material.001']} position={[-1.034, 0, 2.548]} rotation={[-0.17, -1.376, 0.142]} scale={1.825} />
            <mesh geometry={nodes.Object_1660.geometry} material={materials['Material.001']} position={[-1.467, 0, 2.285]} rotation={[3.138, 0.466, -3.136]} scale={1.253} />
            <mesh geometry={nodes.Object_1662.geometry} material={materials['Material.001']} position={[-1.189, 0, 2.503]} rotation={[0.482, -0.868, 0.235]} scale={1.33} />
            <mesh geometry={nodes.Object_1664.geometry} material={materials['Material.001']} position={[-1.371, 0, 2.621]} rotation={[3.095, 0.965, 3.108]} scale={1.192} />
            <mesh geometry={nodes.Object_1666.geometry} material={materials['Material.001']} position={[-1.634, 0, 2.466]} rotation={[-3.037, -0.23, -3.032]} scale={1.486} />
            <mesh geometry={nodes.Object_1668.geometry} material={materials['Material.001']} position={[-1.858, 0, 2.504]} rotation={[-3.001, -0.223, 2.901]} scale={1.736} />
            <mesh geometry={nodes.Object_1670.geometry} material={materials['Material.001']} position={[-1.792, 0, 2.646]} rotation={[0.103, 0.401, -0.357]} scale={1.07} />
            <mesh geometry={nodes.Object_1672.geometry} material={materials['Material.001']} position={[-1.89, 0, 2.614]} rotation={[-3.07, -1.524, -3.032]} scale={1.25} />
            <mesh geometry={nodes.Object_1674.geometry} material={materials['Material.001']} position={[2.055, 0, -2.627]} rotation={[0.097, -0.622, 0.321]} scale={1.327} />
            <mesh geometry={nodes.Object_1676.geometry} material={materials['Material.001']} position={[-2.189, 0, 2.383]} rotation={[-0.031, -0.771, 0.161]} scale={1.975} />
            <mesh geometry={nodes.Object_1678.geometry} material={materials['Material.001']} position={[-1.931, 0, 2.282]} rotation={[-0.091, -0.244, -0.044]} scale={1.446} />
            <mesh geometry={nodes.Object_1680.geometry} material={materials['Material.001']} position={[-2.201, 0, 2.484]} rotation={[0.054, -0.794, 0.125]} scale={1.584} />
            <mesh geometry={nodes.Object_1682.geometry} material={materials['Material.001']} position={[-2.222, 0, 2.652]} rotation={[-1.486, 1.488, 1.483]} scale={0.985} />
            <mesh geometry={nodes.Object_1684.geometry} material={materials['Material.001']} position={[-2.464, 0, 2.407]} rotation={[2.882, -0.721, 2.942]} scale={1.706} />
            <mesh geometry={nodes.Object_1686.geometry} material={materials['Material.001']} position={[-2.484, 0, 2.628]} rotation={[3.113, 1.152, 3.069]} scale={1.579} />
            <mesh geometry={nodes.Object_1688.geometry} material={materials['Material.001']} position={[1.143, -0.009, -3.756]} rotation={[0, 1.042, 0]} scale={0.942} />
            <mesh geometry={nodes.Object_1690.geometry} material={materials['Material.001']} position={[-0.101, -0.009, -3.57]} scale={0.758} />
        </group>
    )
}

useGLTF.preload('./models/the_forest_and_the_cat.glb')
