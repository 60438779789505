import styled from "styled-components";


 const Button = styled.a`
    border-radius: 2em;
    border: none;
    font-size: 10px;
    padding: 0.5em 1em;
    text-decoration:none;
    color: ${props => props.theme.colors.white};
    background: linear-gradient(288deg, rgba(153,104,255,1) 0%, rgba(255,108,178,1) 50%, rgba(255,175,86,1) 100%);
    max-width: 6em;
    text-align: center;
    transition: all ease-in-out 0.2s;


    ${props => props.theme.breakpoints.lg}{
     max-width: 8em;
     font-size: 0.8em;
     padding: 0.5em 1.5em;
    }
  
    span{
     position: relative;
    }

    &.animate.shake:hover {
     animation: shake .6s ease-in-out both;
  
     span {
      animation: shakeText .5s ease-in-out both;
      animation-delay: 0.05s;
     }
    }

    @keyframes shake {
     25% {
      transform: rotate(3deg);
     }
     50% {
      transform: rotate(-3deg);
     }
     100% {
      transform: rotate(0deg);
     }
    }
  
    @keyframes shakeText {
     0% {
      right: 4px;
     }
     33% {
      right: -4px;
     }
     66% {
      right: 4px;
     }
     100% {
      right: 0;
     }
    }
`

export default Button;