import React from'react';
import styled from "styled-components";

const TagList = styled.div`
  width: 30rem;
  max-width: 90vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem 0;
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
  
  @media (max-width: 576px) {
    width: 20rem;
  }
`

const LoopSlider = styled.div`
  .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }

  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  background-color: #334155;
  border-radius: 0.4rem;
  padding: 0.7rem 1rem;
  margin-right: 1rem; // Must used margin-right instead of gap for the loop to be smooth
  box-shadow:
          0 0.1rem 0.2rem rgb(0 0 0 / 20%),
          0 0.1rem 0.5rem rgb(0 0 0 / 30%),
          0 0.2rem 1.5rem rgb(0 0 0 / 40%);

  span {
    font-size: 1rem;
    color: #64748b;
  }
`

const Fade = styled.div`
    pointer-events: none;
    background: linear-gradient(90deg, #1c1c1c70, transparent 30%, transparent 70%, #1c1c1c70);
    position: absolute;
    inset: 0;
`



const ROWS = 2;
const TAGS_PER_ROW = 20;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort( () => .5 - Math.random() );

const Slider = ({children, duration, reverse = false}) => {
    return (
        <LoopSlider style={{
            '--duration': `${duration}ms`,
            '--direction': reverse ? 'reverse' : 'normal'
        }}>
            <div className='inner'>
                {children}
                {children}
            </div>
        </LoopSlider>
    );
};

const TagName = ({ text }) => (
    <Tag><span>#</span> {text}</Tag>
);

export const InfiniteLoopSlider = ({tags,duration}) => {
    return <TagList>
        {[...new Array(ROWS)].map((_, i) => (
            <Slider key={i} duration={duration ?? 500000} reverse={i % 2}>
                {tags.slice(0, TAGS_PER_ROW).map(tag => (
                    <TagName text={tag} key={tag}/>
                ))}
            </Slider>
        ))}
        <Fade/>
    </TagList>
};