import styled from "styled-components";

const Name = styled.span`
    font-size:1rem;
    font-family: ${props => props.theme.typography.inter};
    font-weight: 700;
    text-align:center;
    background: linear-gradient(135deg, rgba(165,120,255,1) 0%, rgba(255,131,189,1) 33%, rgba(255,186,109,1) 66%, rgba(251,233,190,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  ${props => props.theme.breakpoints.lg}{
    font-size: ${props => props.size==="sm" ? "1.5em" : "2.75em"};
  }
`

export default Name;