
import Navbar from '../components/Navbar';
import {useEffect, useRef, useState} from 'react';
import { Canvas } from "@react-three/fiber";
import {Experience} from "../components/Experience";
import { DepthOfField, EffectComposer, Noise, Vignette } from '@react-three/postprocessing'

const navbarLinks = [
  {'title':'Home','key':'home'},
  {'title':'About Me','key':'about'},
  {'title': 'Skills','key':'skills'},
  {'title': 'Experience','key':'experience'},
  {'title': 'Projects','key':'projects'}
];



const Home = () => {
  const [activeMenu, setActiveMenu] = useState('home');
  const canvasRef = useRef();
  const scrollableRef = useRef();

  const handleNavbarClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleScrollProgress = (scrollProgress) => {
      if(parseInt((scrollProgress*100).toFixed(0)) === 0) {
          setActiveMenu(()=>'home')
      }
      if(parseInt((scrollProgress*100).toFixed(0)) === 11) {
        setActiveMenu(()=>'about')
      }
      if(parseInt((scrollProgress*100).toFixed(0)) === 29) {
          setActiveMenu(()=>'skills')
      }
      if(parseInt((scrollProgress*100).toFixed(0)) === 50) {
          setActiveMenu(()=>'experience')
      }
      if(parseInt((scrollProgress*100).toFixed(0)) === 99) {
          setActiveMenu(()=>'projects')
      }
  }


  return (
    <>
      <Navbar menus={navbarLinks} activeMenu={activeMenu} handleNavbarClick={handleNavbarClick}/>
      <Canvas shadows
          camera={{
            fov: 95,
            position: [-5, -0.5, 2],
          }}
          ref={canvasRef}
          id="canvas"
      >
        <Experience handleScrollProgress={handleScrollProgress} activeMenu={activeMenu}/>
        <EffectComposer>
          <DepthOfField focusDistance={0} focalLength={0.1} bokehScale={2} height={480} />
          <Noise opacity={0.07} />
          <Vignette eskil={false} offset={0.1} darkness={1.1} />
        </EffectComposer>
      </Canvas>
    </>
  )
}

export default Home;