import styled from "styled-components";

const Text = styled.span`
    font-weight: bold;
    color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.black};
    font-size: ${props=> props.size === "lg" ? "4em" : "3em"};

      ${props => props.theme.breakpoints.sm}{
        font-size: ${props=> props.size === "lg"? "1em" : "0.75em"}
      }
    
      ${props => props.theme.breakpoints.md}{
        font-size: ${props=> props.size === "lg"? "2em" : "1.5em"}
      }

      ${props => props.theme.breakpoints.lg}{
        font-size: ${props=> props.size === "lg"? "3em" : "2.5em"}
      }
`

export const Subtitle = styled.span`
    font-size: 1.15em;
    font-weight: 400;
    line-height: 1.5;
    color: ${props => props.theme.colors.gray};
`

export default Text;