import {Scroll, useScroll} from "@react-three/drei";
import {useFrame} from "@react-three/fiber";
import {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {FaAngular, FaGithub, FaLinkedin, FaReact} from 'react-icons/fa';
import {TbBrandNextjs} from "react-icons/tb";
import {SiSolidity} from "react-icons/si";
import {BsFillBadge3DFill} from "react-icons/bs";
import {InfiniteLoopSlider} from "./InfiniteLoopSlider";
// import { Link } from "react-router-dom";
import {Project1Img} from "../assets/project1-img.png";
import {Project2Img} from "../assets/project2-img.png";


const StyledContainer = styled.div`
  display: flex;
  flex-direction: ${props => props?.direction};
  align-items: ${props => props?.align};
  justify-content: ${props => props?.justify};
  background-color: ${props => props?.background ?? "#ffffff20"};
  padding: 0 12px;
  border-radius: 8px;
  margin: auto;
  height: 90vh;
  opacity: ${props => props?.opacity ?? 1};


  &.xxl {
    width: 95%;
    max-width: 95%;
  }

  &.xl {
    width: 85%;
    max-width: 85%;
  }

  &.lg {
    width: 65%;
    max-width: 65%;
  }

  &.md {
    width: 45%;
    max-width: 45%;
  }

  &.sm {
    width: 30%;
    max-width: 30%;
  }
  
  @media (max-width:576px) {
    &.xxl {
      width: 90%;
      max-width: 90%;
    }
    
    &.lg {
      width: 80%;
      max-width: 80%;
    }
  }

`

const Row = styled.div`
  display: flex;
  row-gap: ${props => props?.rowGap ??' 0.5rem'};
  column-gap: ${props => props?.columnGap ??' 0.5rem'};
  flex-direction: ${props => props?.direction};
  align-items: ${props => props?.align ?? "center"};
  justify-content: ${props => props?.justify ?? "center"};
  opacity: ${props => props?.opacity ?? 1};
  
  &.border{
    &.primary{
      border: 1px solid #ffba6d60;
    }
    
    &.secondary{
      border: 1px solid #141A0450;
    }
    
    &.tertiary{
      border: 2px dotted #835BE170;
    }

    border-radius: 8px;
    padding: 1rem;
  }
  
    //small screens
    @media (max-width: 576px) {
      flex-wrap: wrap !important;
      row-gap: 1rem;
      column-gap: 0.25rem;
    } 
  
`

const Title = styled.h1`
  color: #EDF1FC;
  font-size: ${props => props?.size === "xxl" ? '72px' : '40px'};
  line-height: 1;
  margin: 0 auto;
  
  //small screens
  @media (max-width: 576px) {
    font-size: ${props => props?.size === "xxl" ? '18px' : '10px'};
  }
  //medium screens
  @media (max-width: 768px) {
    font-size: ${props => props?.size === "xxl" ? '36px' : '20px'};
  }
  //large screens
  @media (max-width: 1024px) {
    font-size: ${props => props?.size === "xxl" ? '54px' : '30px'};
  }
`

const Subtitle = styled.h2`
  color: #EDF1FC;
  margin: 0 auto;
  font-size: ${props => props?.size === "lg" ? '32px' : (props?.size === "sm" ? '16px' : '24px')};
  
  //small screens
  @media (max-width: 576px) {
    text-align: center;
    font-size: ${props => props?.size === "lg" ? '12px' : (props?.size === "sm" ? '8px' : '10px')};
  }
  //medium screens
  @media (max-width: 768px) {
    font-size: ${props => props?.size === "lg" ? '18px' : (props?.size === "sm" ? '10px' : '12px')};
  }
  //large screens
  @media (max-width: 1024px) {
    font-size: ${props => props?.size === "lg" ? '24px' : (props?.size === "sm" ? '14px' : '18px')};
  }
  
`

const Text = styled.div`
  font-size: ${props => props?.size === "sm" ? '14px' : '18px'};
  color: #EDF1FC;
  text-align: ${props => props?.align ?? 'start'};
  line-height: 1.35;
  
  &.primary{
    color: #ffba6d; 
  }

  //small screens
  @media (max-width: 576px) {
    font-size: ${props => props?.size === "sm" ? '10px' : '12px'};
    text-align: center;
  }
`

const Icon = styled.span`
  font-size: 16px;
  color: #EDF1FC;
  margin-right: 4px;
  vertical-align: bottom;
`

const TextAccent = styled.span`
  color: #EDF1FC;
  background: linear-gradient(135deg, rgba(165,120,255,1) 0%, rgba(255,131,189,1) 33%, rgba(255,186,109,1) 66%, rgba(251,233,190,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &.primary{
    border: 1px solid #ffba6d;
  }

  &.secondary{
    border: 1px solid #141A04;
  }

  &.tertiary{
    border: 1px solid #835BE1;
  }
`

const Spacer = styled.div`
  height: ${props => props?.height ?? '2rem'};
`

const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props?.direction};
  align-items: ${props => props?.align ?? "center"};
  justify-content: ${props => props?.justify ?? "center"};
  column-gap: ${props => props?.columnGap ?? '0.5rem'};
  row-gap: ${props => props?.rowGap ?? '0.5rem'};
  border: 1px solid rgba(226,232,255,.1);
  border-radius: 4px;
  padding: 1rem;
  max-width:${props => props?.maxWidth ?? 'auto'};
  position:relative;
  background-color:rgba(226,232,255,.1);
  backdrop-filter: blur(10px);


  &.inner{
    background: transparent;
    min-height: 8rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
  }
  
  &.withBG {
    background-color:#141A0480;
    border-radius: 15px;
    backdrop-filter: blur(10px);
  }
  
  &.custom{
    //small screens
    @media (max-width: 576px) {
      width:27%;
      padding: 0.5em;
      text-align: center;
    }
    
    &.experience{
      @media (max-width: 576px) {
        width:95% !important;
        padding: 0.5em;
      }
    }
  }
`

const AnimatedBorder = styled.div`

  span.border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;

    &:nth-child(1)
    {
      transform:rotate(0deg);
    }

    &:nth-child(2)
    {
      transform:rotate(180deg);
    }

    &:before
    {
      content: '';
      position: absolute;
      width:100%;
      height: 1px;
      left:0;
      right:0;
      background: linear-gradient(135deg, rgba(165,120,255,1) 0%, rgba(255,131,189,1) 33%, rgba(255,186,109,1) 66%, rgba(251,233,190,1) 100%);
      animation: animate 10s linear infinite;
    }
  }

  @keyframes animate {
    0% {
      transform:scaleX(0);
      transform-origin: left;
    }
    50%
    {
      transform:scaleX(1);
      transform-origin: left;
    }
    50.1%
    {
      transform:scaleX(1);
      transform-origin: right;

    }

    100%
    {
      transform:scaleX(0);
      transform-origin: right;

    }
`

const ProjectBox = styled.div`
  width: 14rem;
  height: 12rem;
  background: linear-gradient(45deg, rgba(128,122,223,0.4) 0%, rgba(162,124,185,0.5) 60%, rgba(247,176,252,0.4) 100%);
  border-radius: 1rem;
  padding: 1rem;
  color: #EDF1FC;
  transition: all 0.3s ease-in-out;

  &:hover{
    background: linear-gradient(90deg, rgba(128,122,223,0.4) 0%, rgba(162,124,185,0.5) 60%, rgba(247,176,252,0.4) 100%);
    box-shadow: 0 0 3px 3px #B8941E80;
  }
  
  @media(max-width: 576px) {
    width: 8rem;
    height: 7rem;
  }
`

const ImageWrapper = styled.div`
    flex: 2;
    position: relative;
    width: 100%;
    height: 100%;
`

const Image = styled.img`
    position: absolute;
    width: ${props => props.width};
    bottom: ${props => props.bottom ? props.bottom : 'unset'};
    top: ${props => props.top ? props.top : 'unset'};
    left: ${props => props.left ? props.left : 'unset'};
    right: ${props => props.right ? props.right : 'unset'};
    `

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height:1.5rem;
  background: #EDF1FC;
  border-radius: 50%;
  position:relative;
  
  &:hover{
    box-shadow: 0 0 3px 3px #B8941E;
  }
`


export const Overlay = ({handleScrollProgress,activeMenu,handleScrollElement}) => {
    const scroll = useScroll();
    const [opacityFirstSection, setOpacityFirstSection] = useState(1);
    const [opacitySecondSection, setOpacitySecondSection] = useState(1);
    const [opacityThirdSection, setOpacityThirdSection] = useState(1);
    const [opacityFourthSection, setOpacityFourthSection] = useState(1);
    const [opacityFifthSection, setOpacityFifthSection] = useState(1);
    const [opacitySixthSection, setOpacitySixthSection] = useState(1);
    const [opacitySeventhSection, setOpacitySeventhSection] = useState(1);
    const [opacityEightSection, setOpacityEightSection] = useState(1);
    const [opacityNinthSection, setOpacityNinthSection] = useState(1);
    const [opacityTenthSection, setOpacityTenthSection] = useState(1);
    const [opacityEleventhSection, setOpacityEleventhSection] = useState(1);
    const [opacityTwelveSection, setOpacityTwelveSection] = useState(1);
    const [opacityThirteenSection, setOpacityThirteenSection] = useState(1);
    const [opacityFourteenSection, setOpacityFourteenSection] = useState(1);

    const SKILLS = ['HTML', 'CSS', 'JavaScript', 'Typescript', 'Tailwind', 'React', 'Next.js', 'Angular', 'Babylon.js', 'Three.js', 'Blockchain','Solidity', 'NodeJS',  'Wordpress', 'UI/UX'];
    const SKILLS2 =  ['Laravel', 'Docker', 'Kubernetes', 'Wordpress', 'Codeigniter', 'Git', 'CI/CD Pipelining', 'Truffle', 'Ganache', 'Ethereum'];
    const SKILLS3 = ['ApexCharts', 'ChartsJS', 'Blender', 'Spline', 'MachineLearning', 'ImageProcessing','Figma', 'Photoshop', 'MySQL', 'PostgreSQL', 'MongoDB'];
    const scrollRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 576);
    }, [window.innerWidth])

    useEffect(() => {
        handleScrollElement(scroll.el)
    },[scroll.el])

    useFrame(() => {
        handleScrollProgress(scroll.offset);
        setOpacityFirstSection(1 - scroll.range(0, 1 / 10));
        setOpacitySecondSection(scroll.range(0.5 / 10, 0.5 / 10));
        setOpacityThirdSection(scroll.range(1 / 10, 1 / 10));
        setOpacityFourthSection(scroll.range(2 / 10, 1 / 10));
        setOpacityFifthSection(scroll.range(2.5 / 10, 0.5 / 10));
        setOpacitySixthSection(scroll.range(3 / 10, 0.5 / 10));
        setOpacitySeventhSection(scroll.range(3.5 / 10, 0.5/ 10));
        setOpacityEightSection(scroll.range(4 / 10, 0.5 / 10));
        setOpacityNinthSection(scroll.range(5 / 10, 1  / 10));
        setOpacityTenthSection(scroll.range(6 / 10, 1 / 10));
        setOpacityEleventhSection(scroll.range(7 / 10, 1 / 10));
        setOpacityTwelveSection(scroll.range(8 / 10, 1 / 10));
        setOpacityThirteenSection(scroll.range(9 / 10, 1 / 10));
        setOpacityFourteenSection(scroll.range(9.25 / 10, 0.25 / 10));
    });
    return (
        <Scroll html>
            <div style={{width: '100vw'}}>
                <StyledContainer className="xl" background="none" opacity={opacityFirstSection} direction="column"
                                 justify="space-between">
                    <Row direction="column">
                        <Title size="xxl" style={{marginTop: '4%'}}>
                            Hi, I'm <TextAccent>Grace</TextAccent>
                        </Title>
                        <Subtitle>
                            I develop web applications, 3d visuals and user interactions
                        </Subtitle>
                        <Subtitle size="sm" ></Subtitle>
                    </Row>
                    <Row direction="column" rowGap="1.5rem">
                        <Row>
                            <Subtitle size="lg">
                                <TextAccent className="accent">Front-End Developer</TextAccent>
                            </Subtitle>
                            <IconContainer>
                                    <a href="https://www.linkedin.com/in/marygraceaguirre/" style={{position:'absolute', bottom:0}}><FaLinkedin/></a>
                            </IconContainer>
                            <IconContainer>
                                    <a href="https://github.com/bluelupinemary" style={{position:'absolute', bottom:0}}><FaGithub/></a>
                            </IconContainer>
                        </Row>
                        <Row>
                            <Text>marygraceaguirre@gmail.com</Text>
                        </Row>
                        <Row>
                            <Card className="card custom">
                                <AnimatedBorder>
                                <span className="border"></span>
                                <span className="border"></span>
                                <Row className="content">
                                    <Icon><FaReact/></Icon>
                                    <Text>React</Text>
                                </Row>
                                </AnimatedBorder>
                            </Card>
                            <Card className="card custom">
                                <AnimatedBorder>
                                    <span className="border"></span>
                                    <span className="border"></span>
                                    <Row className="content">
                                        <Icon><FaAngular/></Icon>
                                        <Text>Angular</Text>
                                    </Row>
                                </AnimatedBorder>
                            </Card>
                            <Card className="card custom">
                                <AnimatedBorder>
                                    <span className="border"></span>
                                    <span className="border"></span>
                                    <Row className="content">
                                        <Icon><TbBrandNextjs/></Icon>
                                        <Text>Next.js</Text>
                                    </Row>
                                </AnimatedBorder>
                            </Card>
                            <Card className="card custom">
                                <AnimatedBorder>
                                    <span className="border"></span>
                                    <span className="border"></span>
                                    <Row className="content">
                                        <Icon><BsFillBadge3DFill/></Icon>
                                        <Text>Three.js</Text>
                                    </Row>
                                </AnimatedBorder>
                            </Card>
                            <Card className="card custom">
                                <AnimatedBorder>
                                    <span className="border"></span>
                                    <span className="border"></span>
                                    <Row className="content">
                                        <Icon><BsFillBadge3DFill/></Icon>
                                        <Text>Babylon.js</Text>
                                    </Row>
                                </AnimatedBorder>
                            </Card>
                            <Card className="card custom">
                                <AnimatedBorder>
                                    <span className="border"></span>
                                    <span className="border"></span>
                                    <Row className="content">
                                        <Icon><SiSolidity/></Icon>
                                        <Text>Blockchain</Text>
                                    </Row>
                                </AnimatedBorder>
                            </Card>
                            <Card className="card custom">
                                <AnimatedBorder>
                                    <span className="border"></span>
                                    <span className="border"></span>
                                    <Row className="content">
                                        <Icon><SiSolidity/></Icon>
                                        <Text>Solidity</Text>
                                    </Row>
                                </AnimatedBorder>
                            </Card>
                        </Row>
                        <Spacer/>
                    </Row>
                </StyledContainer>

                <StyledContainer className="lg" background="none" opacity={opacitySecondSection} direction="column"
                                 align="center" justify="center">
                    <Card direction="column" className="withBG" rowGap="1.5rem">
                        <Title>
                            <TextAccent>About Me</TextAccent>
                        </Title>
                        <Row>
                            <Card className="withBG" direction="column" style={{minWidth:'10rem'}}>
                                <Text size="small">Experience</Text>
                                <Subtitle>9+ years</Subtitle>
                            </Card>
                            <Card className="withBG" direction="column" style={{minWidth:'10rem'}}>
                                <Text size="small">Freelance</Text>
                                <Subtitle>10+ projects</Subtitle>
                            </Card>
                        </Row>
                        <Text>
                            Dubai-based coder passionate about problem-solving and design.
                            <br/>
                            Teaching and hands-on experience are my learning tools.
                        </Text>

                    </Card>
                </StyledContainer>
                <StyledContainer className="lg" background="none" opacity={opacityThirdSection} direction="column"
                                 align="center" justify="center">
                    <Card direction="column" className="withBG">
                        <Text>
                            The dynamic world of programming fuels my growth.
                        </Text>
                        <br/>
                        <Text>
                            Beyond code, I'm into music, gaming, travel and tech exploration.
                        </Text>
                    </Card>
                </StyledContainer>

                <StyledContainer className="xxl" background="none" opacity={opacityFourthSection} direction="column"
                                 align="flex-start" justify="space-around">
                    <Card direction="column" className="withBG" maxWidth={isMobile ? '20rem':'30rem'}>
                        <Row direction="column">
                            <Title>
                                <TextAccent>My Skills</TextAccent>
                            </Title>
                            <Text align="center">Skilled in <TextAccent>3D web development and blockchain integration</TextAccent>,
                                <br/>I focus on crafting user-friendly, visually appealing websites.
                                <br/>Alongside project management, testing, debugging, and web design. </Text>
                        </Row>
                        <Spacer height="4rem"/>
                        <Row direction="column">
                            <Text><TextAccent>Programming & Scripting Languages</TextAccent></Text>
                            <InfiniteLoopSlider tags={SKILLS}/>
                        </Row>
                        <Spacer height="2rem"/>
                        <Row direction="column" opacity={opacityFifthSection}>
                            <Text><TextAccent>Web Technologies & Frameworks</TextAccent></Text>
                            <InfiniteLoopSlider tags={SKILLS2} duration={70000}/>
                        </Row>
                        <Spacer height="2rem"/>
                        <Row direction="column" opacity={opacitySixthSection}>
                            <Text><TextAccent>Databases & Others</TextAccent></Text>
                            <InfiniteLoopSlider tags={SKILLS3} duration={60000}/>
                        </Row>
                    </Card>
                </StyledContainer>
                <StyledContainer className="xxl" background="none" opacity={opacitySeventhSection} direction="column">
                </StyledContainer>
                <StyledContainer className="xxl" background="none" opacity={opacityEightSection} direction="column"
                                 align="flex-end" justify="space-around">
                    <Card direction="column" maxWidth="40rem" className="withBG custom experience" style={{width: isMobile ? '30rem' : '40rem'}}>
                            <Title>
                                <TextAccent>Work Experience</TextAccent>
                            </Title>
                    </Card>
                    <Card direction="column" maxWidth="40rem" className="withBG custom experience" style={{width: isMobile ? '30rem' : '40rem'}}>
                        <AnimatedBorder>
                            <span></span>
                            <span className="border"></span>
                            <Row className="border secondary" align="flex-start">
                                <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                    <Text size="sm" style={{minWidth:'10rem'}}>Sept 2022 - Now</Text>
                                    <Text size="sm"> Abu Dhabi, UAE</Text><br/>
                                </Row>
                                <Row direction="column">
                                    <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                        <Text><TextAccent>Frontend Web Developer - G42 AIQ</TextAccent></Text>
                                        <Card className="inner">
                                            <Text size="sm">Review app requirements and designs, identifying web-based interactions.</Text>
                                            <Text size="sm">Develop <TextAccent>responsive UI components</TextAccent> using <TextAccent>React</TextAccent> and <TextAccent>Angular</TextAccent>.</Text>
                                            <Text size="sm">Write code in <TextAccent>TypeScript</TextAccent>, troubleshooting and debugging as needed. Design front-end architecture, monitor app performance, and document updates.</Text>
                                        </Card>
                                    </Row>
                                </Row>
                            </Row>
                        </AnimatedBorder>
                    </Card>
                </StyledContainer>
                <StyledContainer className="xxl" background="none" opacity={opacityNinthSection} direction="column" align="flex-end">
                    <Card direction="column" maxWidth="40rem" className="withBG custom experience" style={{width: isMobile ? '30rem' : '40rem'}}>
                        <AnimatedBorder>
                            <span></span>
                            <span className="border"></span>
                            <Row className="border secondary" align="flex-start">
                                <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                    <Text size="sm" style={{minWidth:'10rem'}}>Oct 2021 - Sept 2022</Text>
                                    <Text size="sm"> Dubai, UAE</Text><br/>
                                </Row>
                                <Row direction="column">
                                    <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                        <Text><TextAccent>Frontend Web Developer - MultiBank Group</TextAccent></Text>
                                        <Card className="inner">
                                            <Text size="sm">Designed and maintained interfaces with <TextAccent>HTML, CSS, JavaScript</TextAccent>, and various technologies
                                                including <TextAccent>Next.js, Docker, and MaterialUI</TextAccent>.</Text>
                                            <Text size="sm"> Developed pages from concept to live, integrating <TextAccent>APIs, RESTful services, and websockets</TextAccent>.</Text>
                                            <Text size="sm">Implemented advanced features and optimized UX for cross-platform websites. Followed SEO best practices and utilized Git for version control and Gitlab for CI/CD pipelines.</Text>
                                        </Card>
                                    </Row>
                                </Row>
                            </Row>
                        </AnimatedBorder>
                    </Card>
                </StyledContainer>
                <StyledContainer className="xxl" background="none" opacity={opacityTenthSection} direction="column" align="flex-end" justify="space-between">
                    <Card direction="column" maxWidth="40rem" className="withBG custom experience" style={{width: isMobile ? '30rem' : '40rem'}} align="">
                        <AnimatedBorder>
                            <span></span>
                            <span className="border"></span>
                            <Row className="border secondary" align="flex-start" justify={isMobile ? "center" : "flex-start"}>
                                <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                    <Text size="sm" style={{minWidth:'10rem'}}> Aug 2019 - Sept 2021</Text>
                                    <Text size="sm"> Dubai, UAE</Text><br/>
                                </Row>
                                <Row direction="column">
                                    <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                        <Text><TextAccent>Frontend Web Developer - Inox Arabian</TextAccent></Text>
                                    </Row>
                                </Row>
                            </Row>
                        </AnimatedBorder>
                    </Card>
                    <Card direction="column" maxWidth="40rem" className="withBG custom experience" style={{width: isMobile ? '30rem' : '40rem'}} align="">
                        <AnimatedBorder>
                            <span></span>
                            <span className="border"></span>
                            <Row className="border secondary" align="flex-start" justify={isMobile ? "center" : "flex-start"}>
                                <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                    <Text size="sm" style={{minWidth:'10rem'}}> Jun 2013 - July 2019</Text>
                                    <Text size="sm"> Philippines</Text><br/>
                                </Row>
                                <Row direction="column">
                                    <Row direction="column" align="flex-start">
                                        <Text><TextAccent>Programming Instructor - University of the Philippines</TextAccent></Text>
                                    </Row>
                                </Row>
                            </Row>
                        </AnimatedBorder>
                    </Card>
                    <Card direction="column" maxWidth="40rem" className="withBG custom experience" style={{width: isMobile ? '30rem' : '40rem'}} align="">
                        <AnimatedBorder>
                            <span></span>
                            <span className="border"></span>
                            <Row className="border secondary" align="flex-start" justify={isMobile ? "center" : "flex-start"}>
                                <Row direction="column" align={isMobile ? "center" : "flex-start"}>
                                    <Text size="sm" style={{minWidth:'10rem'}}> June 2012 - July 2013</Text>
                                    <Text size="sm"> Philippines</Text><br/>
                                </Row>
                                <Row direction="column">
                                    <Row direction="column" align="flex-start">
                                        <Text><TextAccent>Web Developer - Manila Cordage</TextAccent></Text>
                                    </Row>
                                </Row>
                            </Row>
                        </AnimatedBorder>
                    </Card>
                </StyledContainer>

                <StyledContainer className="xxl" background="none" opacity={opacityEleventhSection} direction="column">
                </StyledContainer>
                <StyledContainer className="xxl" background="none" opacity={opacityTwelveSection} direction="column">
                </StyledContainer>
                <StyledContainer className="xxl" background="none" opacity={opacityThirteenSection} direction="column" align="flex-start">
                    <Card direction="column" maxWidth="40rem" className="custom experience" style={{width: isMobile ? '30rem' : '40rem'}} align="">
                        <Title><TextAccent>Projects</TextAccent></Title>
                        <Spacer height={'3rem'}/>
                        <Row direction="column" rowGap='1rem'>
                            <Row opacity={opacityThirteenSection} columnGap='1rem'>
                                <ProjectBox>
                                    <a href="https://ai-image-generator-two-roan.vercel.app/">
                                      <ImageWrapper>
                                        <Image className="" src="./project1-img.png" width="100%" height="100%" alt="Project 1" />
                                      </ImageWrapper>
                                    </a> 
                                  </ProjectBox>
                                <ProjectBox>
                                  <a href="https://3d-shop-with-4iv408172-bluelupinemarys-projects.vercel.app/">
                                      <ImageWrapper>
                                      <Image className="" src="./project2-img.png" width="100%" height="100%" alt="Project 2" />
                                      </ImageWrapper>
                                    </a> 
                                </ProjectBox>
                            </Row>
                            <Row opacity={opacityFourteenSection} columnGap='1rem'>
                                <ProjectBox>Project 3</ProjectBox>
                                <ProjectBox>Project 4</ProjectBox>
                            </Row>
                            <Row opacity={opacityFourteenSection} columnGap='1rem'>
                                <ProjectBox>Project 5</ProjectBox>
                                <ProjectBox>Project 6</ProjectBox>
                            </Row>
                        </Row>
                    </Card>
                </StyledContainer>

            </div>
        </Scroll>
    );
};