import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.typography.inter};
    color: ${(props) => props.theme.colors.text};
    margin: 0;
    background: ${(props) => props.theme.colors.background.default}
  }

  #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #222831;
  }

`;

export default GlobalStyles;
