import {
    Nav,
    NavLink,
    NavMenu,
    NavBtn,
    Item,
    LeftNav,
} from './NavbarElements';
import Button from './Button';
import Name from './Name';
import { useState } from 'react';

const Navbar = ({menus,activeMenu, handleNavbarClick}) => {
    const [selected, setSelected] = useState('home');

    const handleClick = (menu) => {
        setSelected(menu);
        handleNavbarClick(menu);
    }

    return (
        <>
        <Nav>
            <LeftNav>
                <Name size="sm">Grace Aguirre</Name>
            </LeftNav>
            <NavMenu>
                {menus.map((item,i) => {
                    return (
                        <NavLink key={i} to="/" onClick={() => handleClick(item.key)} isActive={activeMenu === item.key ? true : false}>
                           <Item className={activeMenu === item.key ? 'active' : ''}>{item.title}</Item>
                        </NavLink>
                    )
                    })
                }
            </NavMenu>
            <NavBtn>
                <Button href="mailto:marygraceaguirre@gmail.com" target="_blank" className="animate shake">
                    <span>Contact Me</span>
                </Button>
                <Button href="resume_grace_aguirre.pdf" download className="animate shake" target="_blank">
                    <span>Get Resume</span>
                </Button>
            </NavBtn>
        </Nav>
        </>
    );
};

export default Navbar;
